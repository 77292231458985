import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
// import Tabs from "../../../components/tabs";
// import TabPanel from "../../../components/tabs/tabpanel";
// import IntermediateFeedback from "./intermediateclientfeedback";
// import PartnerFeedback from "./partnerfeedback";
// import ProfessionalFeedback from "./professionalclientfeedback";

import Search from "../../../../components/search";
// import { list } from "./data";
import {
  setPracticeFeedBacks,
  setPracticeFeedbackPage,
  setPracticeFeedbackrowsPerPage,
} from "../../../../reducer/adminSlice";
import {
  deleteFeedbackById,
  filterAllFeedbacks,
  getFeedbacks,
  getPracticeFeedbacks,
  getsnglefeedback,
} from "../../../../services/admin";
import InfoPopup from "../../../../components/infopopup";
import "../feedback.css";
import { toast } from "react-toastify";
import EditFeedback from "../subcomponent/editfeedback";
import PublishFeedback from "../subcomponent/publishfeedback";
import { Link, useNavigate } from "react-router-dom";
import CsvDownload from "../../../../components/csvdownload";
import { filterList } from "../data";
import { setLoading } from "../../../../reducer/auth/auth";
import Pagination from "../../../../components/tablePagination";
import PublishFeedbackPractice from "../subcomponent/publishfeedbackpractice";

export default function PracticesFeedback() {
  const {
    practicefeedBacks,
    practicefeedbackPage,
    feedbackrowsPerPage,
    globalRowsPerPage,
  } = useSelector((state) => state.users);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const [activeTab, setActiveTab] = useState(0);
  const [localFeedBacks, setLocalFeedBacks] = useState([]);
  const [active, setActive] = useState([]);
  const [editFeedbackDetail, setEditFeedbackDetail] = useState();
  const [localFeedBacksstatus, setLocalFeedBacksstatus] = useState([]);
  const [searchValue, setSearchValues] = useState();
  const [filterValues, setFilterValues] = useState("screen_name");
  const [sessionType, setSessionType] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(10);

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords =
    localFeedBacks;
  const nPages =
    localFeedBacks.length > 0
      ? Math.ceil(localFeedBacks.length / recordsPerPage)
      : "";

  const [totalcount, setTotalCount] = useState(0);


  useEffect(() => {
    callBack();
  }, []);
  useEffect(() => {
    // let response = practicefeedBacks

    let info = practicefeedBacks.map((data) => {
      return false;
    });

    // let newa = [...practicefeedBacks].length > 0 && [...practicefeedBacks].sort(function (a, b) {

    //   return new Date(Object.values(b).length > 1 ? Object.values(b)[1].date : Object.values(b)[0].date) - new Date(Object.values(a).length > 1 ? Object.values(a)[1].date : Object.values(a)[0].date);
    // });
    setLocalFeedBacksstatus(info);
    // console.log(practicefeedBacks, "practicefeedBacks");
    setLocalFeedBacks(practicefeedBacks);
    // console.log(newa, "newa")
  }, [practicefeedBacks]);

  function groupBy(objectArray, property) {
    return objectArray.reduce(function (accumulator, currentObject) {
      let key = currentObject[property];
      if (!accumulator[key]) {
        accumulator[key] = [];
      }
      accumulator[key].push(currentObject);
      return accumulator;
    }, {});
  }

  const callBack = (dat, activeTab) => {
    dispatch(setLoading(true));
    // console.log('working')
    console.log("wori");
    getPracticeFeedbacks(dat, 1)
      .then((data) => {
        dispatch(setLoading(false));
        if (data.data.status) {
          setTotalCount(data.data.data.total);


          // let obj = Object.values(grouped).map((val) => {
          //   return Object.assign({}, val);
          // });
          console.log(dat, "obj");
          // dispatch(setPracticeFeedbackPage(dat !== undefined ? dat.page : practicefeedbackPage));
          // dispatch(setPracticeFeedbackrowsPerPage(dat !== undefined ? dat.rowsPerPage : feedbackrowsPerPage));
          dispatch(setPracticeFeedBacks(data.data.data.data));
        }
      })
      .catch(() => {
        dispatch(setLoading(false));
      });
  };

  const openMoreInfo = (info) => {
    console.log("admin info", info);
    return;
    let array = [];
    Object.values(info).map((item) => {
      array.push(item.feedback);
    });

    setActive(array);
    console.log(array, "array");
    window.loadModal2("#infopopup", "show");
    return;
  };

  const deleteFeedback = (id) => {
    let body = {
      id: id,
    };
    deleteFeedbackById(body)
      .then((data) => {
        if (data.data.status) {
          console.log(localFeedBacks,"localFeedBacks")
          const filtered = localFeedBacks.filter((item) => parseInt(item.appointment_id) !== parseInt(id));
          setLocalFeedBacks(filtered);
          // callBack();
          toast.success("Feedback deleted successfully", {
            position: "top-center",
          });
        }
      })
      .catch(() => {
        toast.error("Error deleting feedback", { position: "top-center" });
      });
  };
  const onEditFeedback = (item) => {
    console.log(item, "item");
    setEditFeedbackDetail([item]);
    // window.loadModal2('#editFeedback', 'show');
    window.loadModal2("#publishFeedback", "show");
  };
  const onClickHandler = (e, index) => {
    console.log(localFeedBacksstatus);
    let info = localFeedBacksstatus.map((data, i) => {
      if (i === index) {
        return !data;
      } else {
        return false;
      }
    });

    setLocalFeedBacksstatus(info);
  };
  const checkObjectLendth = (item, info) => {
    if (item.length > 1) {
      return item.feedback[info] !== null ? item.feedback[info] : "";
    } else {
      return item.feedback[info] !== null ? item.feedback[info] : "";
    }
  };
  const navigateEditClient = (e, id) => {
    if (e.target.tagName === "LABEL" || e.target.tagName === "INPUT") {
    } else {
      navigate(`/edit/${id.client_id}`);
    }
  };

  const navigateEditMedium = (e, id) => {
    if (e.target.tagName === "LABEL" || e.target.tagName === "INPUT") {
    } else {
      navigate(`/profile-edit/${id.medium_id}`);
    }
  };
  const filterFeedbacks = (constants) => {
    const {
      name = "",
      session_id = "",
      screen_name = "",
      medium_email = "",
      client_email = "",
      session_name = "",
      session_type = 1
    } = constants;
    let ids = session_id

    let newId = ids

    let body = {
      name,
      newId,
      screen_name,
      medium_email,
      client_email,
      session_name,
      session_type
    };
    filterAllFeedbacks(body)
      .then((data) => {
        if (data.data.status) {
          dispatch(setPracticeFeedbackPage(practicefeedbackPage + 1));
          dispatch(setPracticeFeedbackrowsPerPage(globalRowsPerPage));
          setTotalCount(data.data.data.total);
          // let grouped = groupBy(data.data.data.data, "appointment");
          // // let array = [];
          // let obj = Object.values(grouped).map((val) => {
          //   return Object.assign({}, val);
          // });
          dispatch(setPracticeFeedBacks(data.data.data.data));
          // dispatch(setAllOutreachClientsCount(data.data.data.total));
        }
      })
      .catch(() => { });
    // }
  };
  const getFeedback = (appoinment) => {
    dispatch(setLoading(true));
    setSessionType([]);
    setActive([]);
    getsnglefeedback(appoinment.appointment_id)
      .then((data) => {
        if (data.data.status) {
          let info = data.data.data;
          console.log(data.data.data, "data.data.data");
          let array = [];
          Object.values(info).map((item) => {
            array.push(item.feedback);
          });
          setSessionType(info);
          setActive(array);
          console.log(array, "array");
          window.loadModal2("#infopopup", "show");
        }
        dispatch(setLoading(false));
      })
      .catch(() => {
        dispatch(setLoading(false));
      });
  };
  const getPaginationData = (data, activeTab) => {
    let dat = {
      ...data,
      screen_name:
        searchValue !== undefined
          ? searchValue.screen_name !== undefined
            ? searchValue.screen_name
            : ""
          : "",
      session_name:
        searchValue !== undefined
          ? searchValue.session_name !== undefined
            ? searchValue.session_name
            : ""
          : "",
      name:
        searchValue !== undefined
          ? searchValue.name !== undefined
            ? searchValue.name
            : ""
          : "",
      medium_email:
        searchValue !== undefined
          ? searchValue.medium_email !== undefined
            ? searchValue.medium_email
            : ""
          : "",
      client_email:
        searchValue !== undefined
          ? searchValue.client_email !== undefined
            ? searchValue.client_email
            : ""
          : "",
      newId:
        searchValue !== undefined
          ? searchValue.session_id !== undefined
            ? searchValue.session_id
            : ""
          : "",
    };

    callBack(dat, activeTab);
  };
  return (
    <>
      <div className="d-flex justify-content-end gap-10 flex-wrap">
        <Search
          callApi={filterFeedbacks}
          list={filterList}
          setSearchValues={setSearchValues}
          setFilterValues={setFilterValues}
          searchValue={searchValue}
          filterValues={filterValues}
        />
      </div>
      <CsvDownload
        href={
          `${process.env.REACT_APP_API_URL_EXPORT}` + "export-feedback-list?session_type=1"
        }
      //  data={fireFilter(clients)} headers={headers} filename={'starterClients'}
      // usertype={"outreach"}
      />
      <div className="dashboard">
        <div className="row">
          <div className="col-md-12">
            <div className="my-ppointments-box appoint-das">
              <div className="table-box-medium admin-feed-page overflow-scroll feedback-table">
                <table className="caseTable">
                  <thead>
                    <tr className="dark">
                      <th rowspan="1">Session #</th>
                      <th rowspan="1">Host Medium Screen Name</th>
                      <th rowspan="1">Booking Medium Screen Name</th>
                      <th rowspan="1">Session Date</th>
                      <th rowspan="1">Survey Date</th>
                      {/* <th className="text-center" colSpan="3">
                        Scores
                      </th> */}
                      {/* <th className="text-center" colspan="3">
                        Comments
                      </th> */}
                      <th rowspan="1">Session Type</th>

                      <th rowspan="1">Actions</th>
                    </tr>
                    <tr className="dark dark-subheading">
                      <th className="white-background">&nbsp;</th>
                      <th className="white-background">&nbsp;</th>
                      <th className="white-background">&nbsp;</th>
                      <th className="white-background">&nbsp;</th>
                      <th className="white-background">&nbsp;</th>
                      {/* <th className="">E</th>
                      <th className="">M</th>
                      <th className="">R</th>
                      <th className="">Overall impression</th>
                      <th className="">Additional feedback</th>
                      <th className="">About</th> */}
                      <th className="white-background">&nbsp;</th>
                      <th className="white-background">&nbsp;</th>
                      <th className="white-background">&nbsp;</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentRecords.length > 0 ? (
                      currentRecords.map((item, index) => (
                        <tr className="innr">
                          <td className="min-width-100">
                            {item.front_appointment_id}
                          </td>
                          <td
                            className="pointer feedback-urls min-width-125"
                            onClick={(e) => navigateEditMedium(e, item)}
                          >
                            {item.screen_name}{item.medium_comment === true && (<b  style={{
                              fontSize: '22px',
                            }}>*</b>) }
                          </td>
                          <td
                            className="pointer feedback-urls min-width-125"
                            onClick={(e) => navigateEditClient(e, item)}
                          >
                            {" "}
                            {item.client_name}{item.client_comment === true && (<b  style={{
                              fontSize: '22px',
                            }}>*</b>) }
                          </td>
                          <td className="min-width-120">
                            {item.session_date}
                            {/* {moment(item.session_time, ["h:mm A"]).format(
                              "hh:mm a"
                            )} */}
                          </td>
                          {/* <td className="min-width-120">{moment.utc(item.date).format("LL")}</td> */}
                          <td className="min-width-120">{item.date}</td>

                          {/* <td className="">
                            {moment(item.session_time, ["h:mm A"]).format(
                              "hh:mm a"
                            )}
                          </td> */}
                          {/* <td className="font-size-14">
                            {checkObjectLendth(
                              item,
                              "recognized_my_loved_one_from_evidence"
                            )}
                          </td>
                          <td className="font-size-14">
                            {checkObjectLendth(item, "meaningful_experience")}
                          </td>
                          <td className="font-size-14">
                            {checkObjectLendth(item, "recommend_To_others")}
                          </td>
                          <td className="min-width-300 font-size-14">
                            {checkObjectLendth(item, "overall_impression")}
                          </td>
                          <td className="min-width-300 font-size-14">
                            {checkObjectLendth(item, "additional_feedback")}
                          </td>
                          <td className="min-width-300 font-size-14">
                            {checkObjectLendth(item, "about_verysoul")}
                          </td> */}

                          <td className="min-width-125">{item.session_type}</td>

                          {/* <td className="action-btn-feedbacks"> */}
                          <td className="dott">
                            <img
                              onClick={(e) => onClickHandler(e, index)}
                              src="/images/Unionblck.png"
                              className="padding-10 pointer"
                              alt="dots"
                            />
                            {localFeedBacksstatus[index] && (
                              <div className="approve-btn-1 approve-btn z-index-1">
                                <span
                                  onClick={() => onEditFeedback(item)}
                                  className="pointer"
                                >
                                  Edit
                                </span>

                                <span
                                  onClick={() =>
                                    deleteFeedback(item.appointment_id)
                                  }
                                >
                                  Delete
                                </span>
                                <span
                                  // onClick={() => openMoreInfo(item)}
                                  onClick={() => getFeedback(item)}
                                >
                                  View
                                </span>
                              </div>
                            )}
                            {/* <img
                              onClick={() => onEditFeedback(item)}
                              className="pointer w-20-px"
                              src="images/icon-feather-edit.png"
                              alt="edit"
                            />
                            <img
                              onClick={() => deleteFeedback(item.appointment_id)}
                              className="pointer"
                              src="../images/icon-b2.png"
                              alt="delete"
                            />
                            <img
                              title="More"
                              onClick={() => openMoreInfo(item)}
                              className="pointer"
                              src="/localImages/openeye.svg"
                              alt="eye"
                            /> */}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={12} className="text-center">
                          No Feedbacks Found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <PublishFeedbackPractice
          editFeedbackDetail={editFeedbackDetail}
          callBack={callBack}
        />
        {/* <EditFeedback editFeedbackDetail={editFeedbackDetail} callBack={callBack} /> */}
        <InfoPopup feedBackinfo={active} sessionType={sessionType} />

        <Pagination
          rowsPerPageOptions={[5, 10, 25]}
          activeTab={"adminfeedback"}
          count={totalcount}
          getPaginationData={getPaginationData}
        />
      </div>
    </>
    // <div className="all-medium-page">
    //   <Tabs list={list} setActiveTab={setActiveTab} />
    //   <ul className="tabs-content">
    //     <TabPanel id={0} activeTab={activeTab}>
    //       <IntermediateFeedback />
    //     </TabPanel>
    //     <TabPanel id={1} activeTab={activeTab}>
    //       <PartnerFeedback />
    //     </TabPanel>
    //     <TabPanel id={2} activeTab={activeTab}>
    //       <ProfessionalFeedback />
    //     </TabPanel>
    //   </ul>
    // </div>
  );
}
