import React from "react";
import "./filter.css";

const Filter = ({ list = [], changeHandler, filterValue, name }) => {
  return (
    <div className="filter-container ">
      <select
        value={filterValue}
        className="pointer cl-pont"
        onChange={(e) => changeHandler(e)}
        name="filter"
      >
        <>
          <option value={""} selected disabled>
            {name}
          </option>
          {list.map((item) => (
            <option value={item.value}>{item.name}</option>
          ))}
        </>
      </select>
    </div>
  );
};
export default Filter;
