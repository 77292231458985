import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Tabs from "../../../components/tabs";
import TabPanel from "../../../components/tabs/tabpanel";
import Upcoming from "./upcoming";
import Cancelled from "./cancelled";
import Completed from "./completed";

import { list } from "./data";
import Pusher from "pusher-js";

//
import {
  setAllAppointments,
  setAllUpcomingCount,
  setAllCompletedCount,
  setAllCancelledCount,
  setAllNoShowCount,
  setAllOngoingCount,
  setUpcomingAppointmentsPage,
  setUpcomingAppointmentsrowsPerPage,
  setCompletedAppointmentsPage,
  setCompletedAppointmentsrowsPerPage,
  setCancelledAppointmentsPage,
  setCancelledAppointmentsrowsPerPage,
  setNoShowAppointmentsPage,
  setNoShowAppointmentsrowsPerPage,
  setAllRestrictedCount,
  setEndedEarlyCount,
  setrestrictedAppointmentsPage,
  setrestrictedAppointmentsrowsPerPage,
  setendedEarlyAppointmentsPage,
  setendedEarlyAppointmentsrowsPerPage,
  setSessionTypes,
} from "../../../reducer/adminSlice";
import { setLoading } from "../../../reducer/auth/auth";

//service
import {
  getAllAppointments,
  changeAppointmentStatus,
  filterAllAppointments,
  getAllUpComingAppointments,
  getAllOngoingAppointments,
  getAllNoShowsAppointments,
  getAllUpCancelledAppointments,
  getAllCompletedAppointments,
  filterAllUpcomingAppointments,
  filterAllCompletedAppointments,
  filterAllCancelleddAppointments,
  filterAllNoShowAppointments,
  filterAllOngoingAppointments,
  getAllRestrictedAppointments,
  getAllEndedEarlyAppointments,
  filterAllRestrictedAppointments,
  filterAllEndedEarlyAppointments,
  getSessionType,
} from "../../../services/admin";

import "./appointments.css";
import NoShows from "./noshows";
import Ongoing from "./Ongoing";
import { setNewTimeZone } from "../../../services/medium";
import { setNewTimeZoneData } from "../../../reducer/userSlice";
import { setTimezoneArray } from "../../../reducer/uidataSlice";
import { getTimezones } from "../../../services/uidata";
import {
  emptyAllSelectBox,
  filterVisibleTimeZones,
} from "../../../utils/helpers";
import { toast } from "react-toastify";
import RestrictedAppointments from "./restricted";
import EndedEarlyAppointments from "./endedearly";
import { da } from "date-fns/locale";
import { items } from "../calendar/data";

export default function Appointments() {
  const {
    allUpcomingAppointments,
    allCompletedAppointments,
    allCancelledAppointments,
    allNoShowsAppointments,
    allOngoingAppointments,
    allUpcomingCount,
    allCompletedCount,
    allCancelledCount,
    allNoShowCount,
    allOngoingCount,
    globalRowsPerPage,
    upcomingAppointmentsPage,
    upcomingAppointmentsrowsPerPage,
    completedAppointmentsPage,
    completedAppointmentsrowsPerPage,
    cancelledAppointmentsPage,
    cancelledAppointmentsrowsPerPage,
    noshowAppointmentsPage,
    restrictedAppointmentsPage,
    restrictedAppointmentsrowsPerPage,
    endedEarlyAppointmentsPage,
    endedEarlyAppointmentsrowsPerPage,
    noshowAppointmentsrowsPerPage,
    ongoingAppointmentsPage,
    ongoingAppointmentsrowsPerPage,
    sessionTypes,
  } = useSelector((state) => state.users);
  const { timezone } = useSelector((state) => state.user.user);
  const { timezoneArray } = useSelector((state) => state.uidata);

  const dispatch = useDispatch();

  const [activeTab, setActiveTab] = useState(0);
  const [upcomingAppointment, setUpcomingAppointments] = useState([]);
  const [completedAppointment, setCompletedAppointments] = useState([]);
  const [canceledAppointment, setCanceledAppointments] = useState([]);
  const [showAppointment, setShowAppointments] = useState([]);
  const [restrictedAppontmets, setRestrictedAppontmets] = useState([]);
  const [endedEarlyAppontmets, setEndedEarlyAppontmets] = useState([]);
  const [showOngoingAppointments, setOngoingAppointments] = useState([]);

  const [listArray, setListArray] = useState(list);
  const [backuupcoming, setbackupUpcoming] = useState([]);
  const [backuupcompleted, setbackupCompleted] = useState([]);
  const [backuupcancelled, setbackupCancelled] = useState([]);
  const [backuupnoshow, setbackupUpNoShow] = useState([]);
  const [backuupnongoing, setbackupOngoing] = useState([]);
  const [timezoneData, setTimezoneData] = useState([]);

  const [filterValues, setFilterValues] = useState("screen_name");
  const [searchValue, setSearchValues] = useState("");

  const [serviceTypeList, setServiceTypeList] = useState([]);
  const [serviceFilterValue, setServiceFilterValue] = useState("");

  const [sortingNameOrderBy, setSortingNameOrderBy] = useState("");
  const [sortingTtabType, setSortingTabType] = useState("");
  const [meetingLinkRegenerated, setmeetingLinkRegenerated] = useState({})

  useEffect(() => {
    if (!sessionTypes.length) getSessionTypes();
    if (!timezoneArray.length) updateTimezones();
    launcApi();
  }, []);
  useEffect(() => {
    setTimezoneData(filterVisibleTimeZones(timezoneArray));
  }, [timezoneArray]);
  useEffect(() => {
    let info = sessionTypes.filter((data) => {
      if (parseInt(data.sub_session_id) !== 78) {
        return {
          ...data,
        };
      }
    });
    setServiceTypeList(
      info.map((data) => {
        return {
          value: data.sub_session_id,
          name: data.sub_session_name,
        };
      })
    );
  }, [sessionTypes]);
  //////
  const getSessionTypes = () => {
    getSessionType()
      .then((data) => {
        dispatch(setSessionTypes(data.data.data));
      })
      .catch(() => { });
  };
  const checkStatus0Values = (data) => {
    if (data.attendees !== undefined) {
      let array = data.attendees.filter((info) => info.status == 0);
      return array;
    } else {
      return [];
    }
    // return data.attendees.length;
  };
  const getUpComingAppointments = (data, activeTab) => {
    dispatch(setLoading(true));

    getAllUpComingAppointments(data, activeTab)
      .then((data) => {
        dispatch(setLoading(false));
        dispatch(
          setAllUpcomingCount(
            JSON.parse(
              JSON.stringify(data.data.data.appointments_count.upcoming)
            )
          )
        );
        dispatch(
          setAllCompletedCount(
            JSON.parse(
              JSON.stringify(data.data.data.appointments_count.completed)
            )
          )
        );
        dispatch(
          setAllCancelledCount(data.data.data.appointments_count.cancelled)
        );
        dispatch(setAllNoShowCount(data.data.data.appointments_count.noshow));
        dispatch(setAllOngoingCount(data.data.data.appointments_count.ongoing));
        dispatch(
          setAllRestrictedCount(data.data.data.appointments_count.restrict)
        );
        dispatch(
          setEndedEarlyCount(data.data.data.appointments_count.endearly)
        );
        setUpcomingAppointments(
          data.data.data.info.map((data) => {
            return {
              ...data,
              statusValue: "",
              nostatusValue: "",
              noOfAttendees: checkStatus0Values(data),
            };
          })
        );
        setListArray(
          list.map((item) => {
            if (item.name === "Upcoming") {
              return {
                ...item,
                ...(item.count = data.data.data.appointments_count.upcoming),
              };
            }
            if (item.name === "Completed") {
              return {
                ...item,
                ...(item.count = data.data.data.appointments_count.completed),
              };
            }

            if (item.name === "Cancelled") {
              return {
                ...item,
                ...(item.count = data.data.data.appointments_count.cancelled),
              };
            }

            if (item.name === "No Shows") {
              return {
                ...item,
                ...(item.count = data.data.data.appointments_count.noshow),
              };
            }
            if (item.name === "Restriced") {
              return {
                ...item,
                ...(item.count = data.data.data.appointments_count.restrict),
              };
            }
            if (item.name === "Ended Early") {
              return {
                ...item,
                ...(item.count = data.data.data.appointments_count.endearly),
              };
            }
            if (item.name === "Ongoing") {
              return {
                ...item,
                ...(item.count = data.data.data.appointments_count.ongoing),
              };
            }
          })
        );
      })
      .catch(() => {
        dispatch(setLoading(false));
      });
  };

  const getCompletedAppointments = (data, activeTab) => {
    dispatch(setLoading(true));
    getAllCompletedAppointments(data, activeTab)
      .then((data) => {
        dispatch(setLoading(false));
        setCompletedAppointments(
          data.data.data.info.map((data) => {
            return {
              ...data,
              statusValue: "",
              nostatusValue: "",
              noOfAttendees: checkStatus0Values(data),
            };
          })
        );
        dispatch(
          setAllUpcomingCount(
            JSON.parse(
              JSON.stringify(data.data.data.appointments_count.upcoming)
            )
          )
        );
        dispatch(
          setAllCompletedCount(
            JSON.parse(
              JSON.stringify(data.data.data.appointments_count.completed)
            )
          )
        );
        dispatch(
          setAllCancelledCount(data.data.data.appointments_count.cancelled)
        );
        dispatch(setAllNoShowCount(data.data.data.appointments_count.noshow));
        dispatch(setAllOngoingCount(data.data.data.appointments_count.ongoing));
        dispatch(
          setAllRestrictedCount(data.data.data.appointments_count.restrict)
        );
        dispatch(
          setEndedEarlyCount(data.data.data.appointments_count.endearly)
        );
        setListArray(
          list.map((item) => {
            if (item.name === "Upcoming") {
              return {
                ...item,
                ...(item.count = data.data.data.appointments_count.upcoming),
              };
            }
            if (item.name === "Completed") {
              return {
                ...item,
                ...(item.count = data.data.data.appointments_count.completed),
              };
            }

            if (item.name === "Cancelled") {
              return {
                ...item,
                ...(item.count = data.data.data.appointments_count.cancelled),
              };
            }

            if (item.name === "No Shows") {
              return {
                ...item,
                ...(item.count = data.data.data.appointments_count.noshow),
              };
            }
            if (item.name === "Restriced") {
              return {
                ...item,
                ...(item.count = data.data.data.appointments_count.restrict),
              };
            }
            if (item.name === "Ended Early") {
              return {
                ...item,
                ...(item.count = data.data.data.appointments_count.endearly),
              };
            }
            if (item.name === "Ongoing") {
              return {
                ...item,
                ...(item.count = data.data.data.appointments_count.ongoing),
              };
            }
          })
        );
      })
      .catch(() => {
        dispatch(setLoading(false));
      });
  };
  const getCancelledAppointments = (data, activeTab) => {
    dispatch(setLoading(true));
    getAllUpCancelledAppointments(data, activeTab)
      .then((data) => {
        dispatch(setLoading(false));
        setCanceledAppointments(
          data.data.data.info.map((data) => {
            return { ...data, statusValue: "", nostatusValue: "" };
          })
        );
        dispatch(
          setAllUpcomingCount(
            JSON.parse(
              JSON.stringify(data.data.data.appointments_count.upcoming)
            )
          )
        );
        dispatch(
          setAllCompletedCount(
            JSON.parse(
              JSON.stringify(data.data.data.appointments_count.completed)
            )
          )
        );
        dispatch(
          setAllCancelledCount(data.data.data.appointments_count.cancelled)
        );
        dispatch(setAllNoShowCount(data.data.data.appointments_count.noshow));
        dispatch(setAllOngoingCount(data.data.data.appointments_count.ongoing));
        dispatch(
          setAllRestrictedCount(data.data.data.appointments_count.restrict)
        );
        dispatch(
          setEndedEarlyCount(data.data.data.appointments_count.endearly)
        );
        setListArray(
          list.map((item) => {
            if (item.name === "Upcoming") {
              return {
                ...item,
                ...(item.count = data.data.data.appointments_count.upcoming),
              };
            }
            if (item.name === "Completed") {
              return {
                ...item,
                ...(item.count = data.data.data.appointments_count.completed),
              };
            }

            if (item.name === "Cancelled") {
              return {
                ...item,
                ...(item.count = data.data.data.appointments_count.cancelled),
              };
            }

            if (item.name === "No Shows") {
              return {
                ...item,
                ...(item.count = data.data.data.appointments_count.noshow),
              };
            }
            if (item.name === "Restriced") {
              return {
                ...item,
                ...(item.count = data.data.data.appointments_count.restrict),
              };
            }
            if (item.name === "Ended Early") {
              return {
                ...item,
                ...(item.count = data.data.data.appointments_count.endearly),
              };
            }
            if (item.name === "Ongoing") {
              return {
                ...item,
                ...(item.count = data.data.data.appointments_count.ongoing),
              };
            }
          })
        );
      })
      .catch(() => {
        dispatch(setLoading(false));
      });
  };
  const getNoShowsAppointments = (data, activeTab) => {
    dispatch(setLoading(true));
    getAllNoShowsAppointments(data, activeTab)
      .then((data) => {
        dispatch(setLoading(false));
        setShowAppointments(
          data.data.data.info.map((data) => {
            return { ...data, statusValue: "", nostatusValue: "" };
          })
        );
        dispatch(
          setAllUpcomingCount(
            JSON.parse(
              JSON.stringify(data.data.data.appointments_count.upcoming)
            )
          )
        );
        dispatch(
          setAllCompletedCount(
            JSON.parse(
              JSON.stringify(data.data.data.appointments_count.completed)
            )
          )
        );
        dispatch(
          setAllCancelledCount(data.data.data.appointments_count.cancelled)
        );
        dispatch(setAllNoShowCount(data.data.data.appointments_count.noshow));
        dispatch(setAllOngoingCount(data.data.data.appointments_count.ongoing));
        dispatch(
          setAllRestrictedCount(data.data.data.appointments_count.restrict)
        );
        dispatch(
          setEndedEarlyCount(data.data.data.appointments_count.endearly)
        );
        setListArray(
          list.map((item) => {
            if (item.name === "Upcoming") {
              return {
                ...item,
                ...(item.count = data.data.data.appointments_count.upcoming),
              };
            }
            if (item.name === "Completed") {
              return {
                ...item,
                ...(item.count = data.data.data.appointments_count.completed),
              };
            }

            if (item.name === "Cancelled") {
              return {
                ...item,
                ...(item.count = data.data.data.appointments_count.cancelled),
              };
            }

            if (item.name === "No Shows") {
              return {
                ...item,
                ...(item.count = data.data.data.appointments_count.noshow),
              };
            }
            if (item.name === "Restriced") {
              return {
                ...item,
                ...(item.count = data.data.data.appointments_count.restrict),
              };
            }
            if (item.name === "Ended Early") {
              return {
                ...item,
                ...(item.count = data.data.data.appointments_count.endearly),
              };
            }
            if (item.name === "Ongoing") {
              return {
                ...item,
                ...(item.count = data.data.data.appointments_count.ongoing),
              };
            }
          })
        );
      })
      .catch(() => {
        dispatch(setLoading(false));
      });
  };
  const getRestrictedAppointments = (data, activeTab) => {
    dispatch(setLoading(true));

    getAllRestrictedAppointments(data, activeTab)
      .then((data) => {
        setRestrictedAppontmets(
          data.data.data.info.map((data) => {
            return { ...data, statusValue: "", nostatusValue: "" };
          })
        );
        dispatch(
          setAllUpcomingCount(
            JSON.parse(
              JSON.stringify(data.data.data.appointments_count.upcoming)
            )
          )
        );
        dispatch(
          setAllCompletedCount(
            JSON.parse(
              JSON.stringify(data.data.data.appointments_count.completed)
            )
          )
        );
        dispatch(
          setAllCancelledCount(data.data.data.appointments_count.cancelled)
        );
        dispatch(setAllNoShowCount(data.data.data.appointments_count.noshow));
        dispatch(setAllOngoingCount(data.data.data.appointments_count.ongoing));
        dispatch(
          setAllRestrictedCount(data.data.data.appointments_count.restrict)
        );
        dispatch(
          setEndedEarlyCount(data.data.data.appointments_count.endearly)
        );
        setListArray(
          list.map((item) => {
            if (item.name === "Upcoming") {
              return {
                ...item,
                ...(item.count = data.data.data.appointments_count.upcoming),
              };
            }
            if (item.name === "Completed") {
              return {
                ...item,
                ...(item.count = data.data.data.appointments_count.completed),
              };
            }

            if (item.name === "Cancelled") {
              return {
                ...item,
                ...(item.count = data.data.data.appointments_count.cancelled),
              };
            }

            if (item.name === "No Shows") {
              return {
                ...item,
                ...(item.count = data.data.data.appointments_count.noshow),
              };
            }
            if (item.name === "Restriced") {
              return {
                ...item,
                ...(item.count = data.data.data.appointments_count.restrict),
              };
            }
            if (item.name === "Ended Early") {
              return {
                ...item,
                ...(item.count = data.data.data.appointments_count.endearly),
              };
            }
            if (item.name === "Ongoing") {
              return {
                ...item,
                ...(item.count = data.data.data.appointments_count.ongoing),
              };
            }
          })
        );
        dispatch(setLoading(false));
      })
      .catch(() => {
        dispatch(setLoading(false));
      });
  };

  const getEndedEarlyAppointments = (data, activeTab) => {
    dispatch(setLoading(true));

    getAllEndedEarlyAppointments(data, activeTab)
      .then((data) => {
        setEndedEarlyAppontmets(
          data.data.data.info.map((data) => {
            return { ...data, statusValue: "", nostatusValue: "" };
          })
        );
        dispatch(
          setAllUpcomingCount(
            JSON.parse(
              JSON.stringify(data.data.data.appointments_count.upcoming)
            )
          )
        );
        dispatch(
          setAllCompletedCount(
            JSON.parse(
              JSON.stringify(data.data.data.appointments_count.completed)
            )
          )
        );
        dispatch(
          setAllCancelledCount(data.data.data.appointments_count.cancelled)
        );
        dispatch(setAllNoShowCount(data.data.data.appointments_count.noshow));
        dispatch(setAllOngoingCount(data.data.data.appointments_count.ongoing));
        dispatch(
          setAllRestrictedCount(data.data.data.appointments_count.restrict)
        );
        dispatch(
          setEndedEarlyCount(data.data.data.appointments_count.endearly)
        );
        setListArray(
          list.map((item) => {
            if (item.name === "Upcoming") {
              return {
                ...item,
                ...(item.count = data.data.data.appointments_count.upcoming),
              };
            }
            if (item.name === "Completed") {
              return {
                ...item,
                ...(item.count = data.data.data.appointments_count.completed),
              };
            }

            if (item.name === "Cancelled") {
              return {
                ...item,
                ...(item.count = data.data.data.appointments_count.cancelled),
              };
            }

            if (item.name === "No Shows") {
              return {
                ...item,
                ...(item.count = data.data.data.appointments_count.noshow),
              };
            }
            if (item.name === "Restriced") {
              return {
                ...item,
                ...(item.count = data.data.data.appointments_count.restrict),
              };
            }
            if (item.name === "Ended Early") {
              return {
                ...item,
                ...(item.count = data.data.data.appointments_count.endearly),
              };
            }
            if (item.name === "Ongoing") {
              return {
                ...item,
                ...(item.count = data.data.data.appointments_count.ongoing),
              };
            }
          })
        );
        dispatch(setLoading(false));
      })
      .catch(() => {
        dispatch(setLoading(false));
      });
  };
  const getOngoingAppointments = (data, activeTab) => {
    dispatch(setLoading(true));
    getAllOngoingAppointments(data, activeTab)
      .then((data) => {
        dispatch(setLoading(false));
        setOngoingAppointments(
          data.data.data.info.map((data) => {
            return { ...data, statusValue: "", nostatusValue: "" };
          })
        );
        dispatch(
          setAllUpcomingCount(
            JSON.parse(
              JSON.stringify(data.data.data.appointments_count.upcoming)
            )
          )
        );
        dispatch(
          setAllCompletedCount(
            JSON.parse(
              JSON.stringify(data.data.data.appointments_count.completed)
            )
          )
        );
        dispatch(
          setAllCancelledCount(data.data.data.appointments_count.cancelled)
        );
        dispatch(setAllNoShowCount(data.data.data.appointments_count.noshow));
        dispatch(setAllOngoingCount(data.data.data.appointments_count.ongoing));
        dispatch(
          setAllRestrictedCount(data.data.data.appointments_count.restrict)
        );
        dispatch(
          setEndedEarlyCount(data.data.data.appointments_count.endearly)
        );
        setListArray(
          list.map((item) => {
            if (item.name === "Upcoming") {
              return {
                ...item,
                ...(item.count = data.data.data.appointments_count.upcoming),
              };
            }
            if (item.name === "Completed") {
              return {
                ...item,
                ...(item.count = data.data.data.appointments_count.completed),
              };
            }

            if (item.name === "Cancelled") {
              return {
                ...item,
                ...(item.count = data.data.data.appointments_count.cancelled),
              };
            }

            if (item.name === "No Shows") {
              return {
                ...item,
                ...(item.count = data.data.data.appointments_count.noshow),
              };
            }
            if (item.name === "Restriced") {
              return {
                ...item,
                ...(item.count = data.data.data.appointments_count.restrict),
              };
            }
            if (item.name === "Ended Early") {
              return {
                ...item,
                ...(item.count = data.data.data.appointments_count.endearly),
              };
            }
            if (item.name === "Ongoing") {
              return {
                ...item,
                ...(item.count = data.data.data.appointments_count.ongoing),
              };
            }
          })
        );
      })
      .catch(() => {
        dispatch(setLoading(false));
      });
  };
  const filterserviceListValue = (list, id) => {
    let info = list.filter((e) => e.value === id);
    return info.length ? info[0].name : "";
  };
  /////
  const getPaginationData = (data, activeTab) => {
    console.log(
      data,
      activeTab,
      "data, activeTab",
      sortingNameOrderBy,
      sortingTtabType
    );
    if (activeTab + 1 === parseInt(sortingTtabType)) {
      Object.assign(data, sortingNameOrderBy);
    }
    let dat = {
      ...data,
      screen_name:
        searchValue !== undefined
          ? searchValue.screen_name !== undefined
            ? searchValue.screen_name
            : ""
          : "",
      session_type:
        serviceFilterValue !== ""
          ? filterserviceListValue(
            serviceTypeList,
            parseInt(serviceFilterValue)
          )
          : "",
      // session_type:
      //   searchValue !== undefined
      //     ? searchValue.session_type !== undefined
      //       ? searchValue.session_type
      //       : ""
      //     : "",
      name:
        searchValue !== undefined
          ? searchValue.name !== undefined
            ? searchValue.name
            : ""
          : "",
      medium_email:
        searchValue !== undefined
          ? searchValue.medium_email !== undefined
            ? searchValue.medium_email
            : ""
          : "",
      client_email:
        searchValue !== undefined
          ? searchValue.client_email !== undefined
            ? searchValue.client_email
            : ""
          : "",
      meeting_id:
        searchValue !== undefined
          ? searchValue.meeting_id !== undefined
            ? searchValue.meeting_id
            : ""
          : "",
      created_at:
        searchValue !== undefined && searchValue.created_at !== undefined
          ? searchValue.created_at
          : "",
    };
    // getAllAppointmentsList
    // getUpComingAppointments(data,activeTab)
    if (activeTab === 0) {
      getUpComingAppointments(dat, activeTab);
    }
    if (activeTab === 1) {
      getCompletedAppointments(dat, activeTab);
    }
    if (activeTab === 2) {
      getCancelledAppointments(dat, activeTab);
    }
    if (activeTab === 3) {
      getNoShowsAppointments(dat, activeTab);
    }
    if (activeTab === 4) {
      getRestrictedAppointments(dat, activeTab);
    }
    if (activeTab === 5) {
      getEndedEarlyAppointments(dat, activeTab);
    }
    if (activeTab === 6) {
      getOngoingAppointments(dat, activeTab);
    }
  };

  const updateTimezones = () => {
    getTimezones()
      .then((data) => {
        dispatch(setTimezoneArray(data.data.data));
      })
      .catch((err) => console.log(err));
  };
  const changeTimeZoneHandler = (e) => {
    let id = e.target.value;
    let body = {
      timezone_id: id,
    };
    setNewTimeZone(body)
      .then((data) => {
        if (data.data.status) {
          const data = {
            page: 1,
            rowsPerPage: 10,
          };
          getAllAppointmentsList(data, activeTab);
          dispatch(setNewTimeZoneData(filterTimeZone(id)));
        }
      })
      .catch(() => { });
  };
  const filterTimeZone = (id) => {
    let zone;
    timezoneData.filter((item) => {
      if (parseInt(id) === parseInt(item.id)) {
        zone = item.front_name;
      }
    });
    return zone;
  };
  useEffect(() => {
    const data = {
      page: 1,
      rowsPerPage: 10,
      screen_name:
        searchValue !== undefined
          ? searchValue.screen_name !== undefined
            ? searchValue.screen_name
            : ""
          : "",
      session_type:
        serviceFilterValue !== ""
          ? filterserviceListValue(
            serviceTypeList,
            parseInt(serviceFilterValue)
          )
          : "",
      // session_type:
      //   searchValue !== undefined
      //     ? searchValue.session_type !== undefined
      //       ? searchValue.session_type
      //       : ""
      //     : "",
      name:
        searchValue !== undefined
          ? searchValue.name !== undefined
            ? searchValue.name
            : ""
          : "",
      medium_email:
        searchValue !== undefined
          ? searchValue.medium_email !== undefined
            ? searchValue.medium_email
            : ""
          : "",
      client_email:
        searchValue !== undefined
          ? searchValue.client_email !== undefined
            ? searchValue.client_email
            : ""
          : "",
      meeting_id:
        searchValue !== undefined
          ? searchValue.meeting_id !== undefined
            ? searchValue.meeting_id
            : ""
          : "",
      created_at:
        searchValue !== undefined && searchValue.created_at !== undefined
          ? searchValue.created_at
          : "",
    };

    if (activeTab === 0) {
      getUpComingAppointments(data, activeTab);
    }
    if (activeTab === 1) {
      getCompletedAppointments(data, activeTab);
    }
    if (activeTab === 2) {
      getCancelledAppointments(data, activeTab);
    }
    if (activeTab === 3) {
      getNoShowsAppointments(data, activeTab);
    }
    if (activeTab === 4) {
      getRestrictedAppointments(data, activeTab);
    }
    if (activeTab === 5) {
      getEndedEarlyAppointments(data, activeTab);
    }
    if (activeTab === 6) {
      getOngoingAppointments(data, activeTab);
    }
    // getAllAppointmentsList(data, activeTab);

    // let resp = {
    //   upcoming: backuupcoming,
    //   completed: backuupcompleted,
    //   cancelled: backuupcancelled,
    //   show: backuupnoshow,
    //   ongoing: backuupnongoing,
    // };
    // dispatch(setAllAppointments(resp));
  }, [activeTab]);

  // useEffect(() => {
  //   setUpcomingAppointments(
  //     allUpcomingAppointments.map((data) => {
  //       return { ...data, statusValue: "", nostatusValue: "" };
  //     })
  //   );
  //   setCompletedAppointments(
  //     allCompletedAppointments.map((data) => {
  //       return { ...data, statusValue: "", nostatusValue: "" };
  //     })
  //   );
  //   setCanceledAppointments(allCancelledAppointments);
  //   setShowAppointments(allNoShowsAppointments);
  //   setOngoingAppointments(allOngoingAppointments);
  //   setListArray(
  //     list.map((item) => {
  //       if (item.name === "Upcoming") {
  //         return {
  //           ...item,
  //           ...(item.count = allUpcomingCount),
  //         };
  //       }
  //       if (item.name === "Completed") {
  //         return {
  //           ...item,
  //           ...(item.count = allCompletedCount),
  //         };
  //       }

  //       if (item.name === "Cancelled") {
  //         return {
  //           ...item,
  //           ...(item.count = allCancelledCount),
  //         };
  //       }

  //       if (item.name === "No Shows") {
  //         return {
  //           ...item,
  //           ...(item.count = allNoShowCount),
  //         };
  //       }
  //       if (item.name === "Ongoing") {
  //         return {
  //           ...item,
  //           ...(item.count = allOngoingCount),
  //         };
  //       }
  //     })
  //   );
  // }, [
  //   allCompletedAppointments,
  //   allUpcomingAppointments,
  //   allCancelledAppointments,
  //   allNoShowsAppointments,
  //   allOngoingAppointments,
  // ]);
  const getAllAppointmentsList = (paginationdata, activeTab, info) => {
    if (info === "refresh") {
      dispatch(setLoading(true));
    }
    let dat = {
      ...paginationdata,
      screen_name:
        searchValue !== undefined
          ? searchValue.screen_name !== undefined
            ? searchValue.screen_name
            : ""
          : "",
      session_type:
        serviceFilterValue !== ""
          ? filterserviceListValue(
            serviceTypeList,
            parseInt(serviceFilterValue)
          )
          : "",
      // session_type:
      //   searchValue !== undefined
      //     ? searchValue.session_type !== undefined
      //       ? searchValue.session_type
      //       : ""
      //     : "",
      name:
        searchValue !== undefined
          ? searchValue.name !== undefined
            ? searchValue.name
            : ""
          : "",
      medium_email:
        searchValue !== undefined
          ? searchValue.medium_email !== undefined
            ? searchValue.medium_email
            : ""
          : "",
      client_email:
        searchValue !== undefined
          ? searchValue.client_email !== undefined
            ? searchValue.client_email
            : ""
          : "",
      meeting_id:
        searchValue !== undefined
          ? searchValue.meeting_id !== undefined
            ? searchValue.meeting_id
            : ""
          : "",
      created_at:
        searchValue !== undefined && searchValue.created_at !== undefined
          ? searchValue.created_at
          : "",
    };
    let appointmentType = "";
    if (activeTab === 0) {
      appointmentType = "upcoming";
    }
    if (activeTab === 1) {
      appointmentType = "completed";
    }
    if (activeTab === 2) {
      appointmentType = "cancelled";
    }
    if (activeTab === 3) {
      appointmentType = "show";
    }
    if (activeTab === 4) {
      appointmentType = "ongoing";
    }
    getAllAppointments(dat, appointmentType)
      .then((data) => {
        dispatch(setLoading(false));
        if (data.data.status) {
          //

          //
          // dispatch();
          dispatch(setAllAppointments(data.data.data));
          setbackupUpcoming(
            JSON.parse(JSON.stringify(data.data.data.upcoming))
          );
          setbackupCompleted(
            JSON.parse(JSON.stringify(data.data.data.completed))
          );
          setbackupCancelled(
            JSON.parse(JSON.stringify(data.data.data.cancelled))
          );
          setbackupUpNoShow(JSON.parse(JSON.stringify(data.data.data.show)));
          setbackupOngoing(JSON.parse(JSON.stringify(data.data.data.ongoing)));

          dispatch(
            setAllUpcomingCount(data.data.data.upcoming_pagination.total)
          );

          dispatch(
            setAllUpcomingCount(
              JSON.parse(
                JSON.stringify(data.data.data.upcoming_pagination.total)
              )
            )
          );

          dispatch(
            setAllCompletedCount(
              JSON.parse(
                JSON.stringify(data.data.data.completed_pagination.total)
              )
            )
          );
          dispatch(
            setAllCancelledCount(
              JSON.parse(
                JSON.stringify(data.data.data.cancelled_pagination.total)
              )
            )
          );
          dispatch(
            setAllNoShowCount(
              JSON.parse(JSON.stringify(data.data.data.show_pagination.total))
            )
          );
          dispatch(
            setAllOngoingCount(
              JSON.parse(
                JSON.stringify(data.data.data.ongoing_pagination.total)
              )
            )
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const launcApi = () => {
    const data = {
      page: 1,
      rowsPerPage: 10,
      screen_name:
        searchValue !== undefined
          ? searchValue.screen_name !== undefined
            ? searchValue.screen_name
            : ""
          : "",
      session_type:
        serviceFilterValue !== ""
          ? filterserviceListValue(
            serviceTypeList,
            parseInt(serviceFilterValue)
          )
          : "",
      // session_type:
      //   searchValue !== undefined
      //     ? searchValue.session_type !== undefined
      //       ? searchValue.session_type
      //       : ""
      //     : "",
      name:
        searchValue !== undefined
          ? searchValue.name !== undefined
            ? searchValue.name
            : ""
          : "",
      medium_email:
        searchValue !== undefined
          ? searchValue.medium_email !== undefined
            ? searchValue.medium_email
            : ""
          : "",
      client_email:
        searchValue !== undefined
          ? searchValue.client_email !== undefined
            ? searchValue.client_email
            : ""
          : "",
      meeting_id:
        searchValue !== undefined
          ? searchValue.meeting_id !== undefined
            ? searchValue.meeting_id
            : ""
          : "",
      created_at:
        searchValue !== undefined && searchValue.created_at !== undefined
          ? searchValue.created_at
          : "",
    };
    getUpComingAppointments(data, activeTab);
    // if (
    //   !allUpcomingAppointments.length > 0 ||
    //   !allCompletedAppointments.length > 0 ||
    //   !allCancelledAppointments.length > 0 ||
    //   !allNoShowsAppointments.length > 0 ||
    //   !allOngoingAppointments.length > 0
    // ) {
    // getAllAppointmentsList(data, activeTab);
    // }
  };
  const changeStatus = (userId, status, appointmentId) => {
    let body = {
      userId: userId,
      status: status,
      appointmentId: appointmentId,
    };
    changeAppointmentStatus(body)
      .then((data) => {
        if (data.data.status) {
          toast.success("Status updated succesfully", {
            position: "top-center",
          });
          window.loadModal2("#ConfirmCancelAdmin", "hide");

          const data = {
            page: 1,
            rowsPerPage: 10,
          };
          if (activeTab === 0) {
            getUpComingAppointments(data, activeTab);
          } else if (activeTab === 1) {
            getCompletedAppointments(data, activeTab);
          }
          // getAllAppointmentsList(data, activeTab);
          emptyAllSelectBox();
        } else {
          toast.error("Something went wrong", { position: "top-center" });
        }
      })
      .catch(() => { });
  };
  const changeHandler = (e, status, appointmentId) => {
    let value = e.target.value;
    changeStatus(value, status, appointmentId);
  };
  const filterListAppointments = (constants) => {
    const {
      session_type = serviceFilterValue !== ""
        ? filterserviceListValue(serviceTypeList, parseInt(serviceFilterValue))
        : "",
      screen_name,
      orderBy = "asc",
      name,
      medium_email,
      client_email,
      meeting_id,
    } = constants;
    let body = {
      session_type,
      screen_name,
      orderBy,
      name,
      medium_email,
      client_email,
      meeting_id,
    };
    filterAllAppointments(body)
      .then((data) => {
        if (data.data.status) {
          dispatch(
            setAllUpcomingCount(data.data.data.upcoming_pagination.total)
          );

          dispatch(
            setAllCompletedCount(data.data.data.completed_pagination.total)
          );
          dispatch(
            setAllCancelledCount(data.data.data.cancelled_pagination.total)
          );
          dispatch(setAllNoShowCount(data.data.data.show_pagination.total));
          dispatch(setAllOngoingCount(data.data.data.ongoing_pagination.total));
          dispatch(setAllAppointments(data.data.data));
        }
      })
      .catch(() => { });
  };

  const filterListUpcomingAppointments = (constants, from, value) => {
    console.log(searchValue, "", filterValues, "", "");
    console.log(constants, "constat");
    if (from !== "filter" && constants.session_type !== undefined) {
      setServiceFilterValue("");
    }
    const {
      session_type,
      screen_name,
      orderBy = "desc",
      order,
      name,
      medium_email,
      client_email,
      meeting_id,
      created_at,
    } = constants;

    let namdefault =
      constants.name == undefined
        ? searchValue !== undefined && searchValue.name !== undefined
          ? searchValue.name
          : ""
        : constants.name;
    let screen_namedefault =
      constants.screen_name == undefined
        ? searchValue !== undefined && searchValue.screen_name !== undefined
          ? searchValue.screen_name
          : ""
        : constants.screen_name;
    let medium_emaildefault =
      constants.medium_email == undefined
        ? searchValue !== undefined && searchValue.medium_email !== undefined
          ? searchValue.medium_email
          : ""
        : constants.medium_email;
    let client_emaildefault =
      constants.client_email == undefined
        ? searchValue !== undefined && searchValue.client_email !== undefined
          ? searchValue.client_email
          : ""
        : constants.client_email;
    let meeting_iddefault =
      constants.meeting_id == undefined
        ? searchValue !== undefined && searchValue.meeting_id !== undefined
          ? searchValue.meeting_id
          : ""
        : constants.meeting_id;
    let created_atdefault =
      constants.created_at == undefined
        ? searchValue !== undefined && searchValue.created_at !== undefined
          ? searchValue.created_at
          : ""
        : constants.created_at;
    let body = {
      orderBy,
      order,
      screen_name:
        constants !== undefined
          ? constants.screen_name !== undefined
            ? constants.screen_name
            : screen_namedefault
          : "",

      session_type:
        from === "filter"
          ? filterserviceListValue(serviceTypeList, parseInt(value))
          : constants !== undefined
            ? constants.session_type !== undefined
              ? constants.session_type
              : filterserviceListValue(
                serviceTypeList,
                parseInt(serviceFilterValue)
              )
            : "",
      // session_type:
      //   constants !== undefined
      //     ? constants.session_type !== undefined
      //       ? constants.session_type
      //       : ""
      //     : "",
      name:
        constants !== undefined
          ? constants.name !== undefined
            ? constants.name
            : namdefault
          : "",
      medium_email:
        constants !== undefined
          ? constants.medium_email !== undefined
            ? constants.medium_email
            : medium_emaildefault
          : "",
      client_email:
        constants !== undefined
          ? constants.client_email !== undefined
            ? constants.client_email
            : client_emaildefault
          : "",
      meeting_id:
        constants !== undefined
          ? constants.meeting_id !== undefined
            ? constants.meeting_id
            : meeting_iddefault
          : "",
      created_at:
        constants !== undefined && constants.created_at !== undefined
          ? constants.created_at
          : created_atdefault,
    };
    filterAllUpcomingAppointments(body)
      .then((data) => {
        if (data.data.status) {
          dispatch(setUpcomingAppointmentsPage(upcomingAppointmentsPage + 1));
          dispatch(setUpcomingAppointmentsrowsPerPage(globalRowsPerPage));
          dispatch(setLoading(false));
          //
          dispatch(
            setAllUpcomingCount(
              JSON.parse(
                JSON.stringify(data.data.data.appointments_count.upcoming)
              )
            )
          );

          dispatch(
            setAllCompletedCount(
              JSON.parse(
                JSON.stringify(data.data.data.appointments_count.completed)
              )
            )
          );
          dispatch(
            setAllCancelledCount(data.data.data.appointments_count.cancelled)
          );
          dispatch(setAllNoShowCount(data.data.data.appointments_count.noshow));
          dispatch(
            setAllOngoingCount(data.data.data.appointments_count.ongoing)
          );

          //
          setUpcomingAppointments(
            data.data.data.info.map((data) => {
              return {
                ...data,
                statusValue: "",
                nostatusValue: "",
                noOfAttendees: checkStatus0Values(data),
              };
            })
          );

          dispatch(
            setAllRestrictedCount(data.data.data.appointments_count.restrict)
          );
          dispatch(
            setEndedEarlyCount(data.data.data.appointments_count.endearly)
          );
          setListArray(
            list.map((item) => {
              if (item.name === "Upcoming") {
                return {
                  ...item,
                  ...(item.count = data.data.data.appointments_count.upcoming),
                };
              }
              if (item.name === "Completed") {
                return {
                  ...item,
                  ...(item.count = data.data.data.appointments_count.completed),
                };
              }

              if (item.name === "Cancelled") {
                return {
                  ...item,
                  ...(item.count = data.data.data.appointments_count.cancelled),
                };
              }

              if (item.name === "No Shows") {
                return {
                  ...item,
                  ...(item.count = data.data.data.appointments_count.noshow),
                };
              }
              if (item.name === "Restriced") {
                return {
                  ...item,
                  ...(item.count = data.data.data.appointments_count.restrict),
                };
              }
              if (item.name === "Ended Early") {
                return {
                  ...item,
                  ...(item.count = data.data.data.appointments_count.endearly),
                };
              }
              if (item.name === "Ongoing") {
                return {
                  ...item,
                  ...(item.count = data.data.data.appointments_count.ongoing),
                };
              }
            })
          );
        }
      })
      .catch(() => { });
  };

  const filterListCompletedAppointments = (constants, from, value) => {
    if (from !== "filter") {
      setServiceFilterValue("");
    }
    const {
      session_type,
      screen_name,
      orderBy = "desc",
      order,
      name,
      medium_email,
      client_email,
      meeting_id,
    } = constants;
    let screen_namedefault =
      constants.screen_name == undefined
        ? searchValue !== undefined && searchValue.screen_name !== undefined
          ? searchValue.screen_name
          : ""
        : constants.screen_name;
    let namdefault =
      constants.name == undefined
        ? searchValue !== undefined && searchValue.name !== undefined
          ? searchValue.name
          : ""
        : constants.name;
    let medium_emaildefault =
      constants.medium_email == undefined
        ? searchValue !== undefined && searchValue.medium_email !== undefined
          ? searchValue.medium_email
          : ""
        : constants.medium_email;
    let client_emaildefault =
      constants.client_email == undefined
        ? searchValue !== undefined && searchValue.client_email !== undefined
          ? searchValue.client_email
          : ""
        : constants.client_email;
    let meeting_iddefault =
      constants.meeting_id == undefined
        ? searchValue !== undefined && searchValue.meeting_id !== undefined
          ? searchValue.meeting_id
          : ""
        : constants.meeting_id;
    let created_atdefault =
      constants.created_at == undefined
        ? searchValue !== undefined && searchValue.created_at !== undefined
          ? searchValue.created_at
          : ""
        : constants.created_at;
    let body = {
      orderBy,
      order,
      screen_name:
        constants !== undefined
          ? constants.screen_name !== undefined
            ? constants.screen_name
            : screen_namedefault
          : "",
      session_type:
        from === "filter"
          ? filterserviceListValue(serviceTypeList, parseInt(value))
          : constants !== undefined
            ? constants.session_type !== undefined
              ? constants.session_type
              : filterserviceListValue(
                serviceTypeList,
                parseInt(serviceFilterValue)
              )
            : "",
      // session_type:
      //   constants !== undefined
      //     ? constants.session_type !== undefined
      //       ? constants.session_type
      //       : ""
      //     : "",
      name:
        constants !== undefined
          ? constants.name !== undefined
            ? constants.name
            : namdefault
          : "",
      medium_email:
        constants !== undefined
          ? constants.medium_email !== undefined
            ? constants.medium_email
            : medium_emaildefault
          : "",
      client_email:
        constants !== undefined
          ? constants.client_email !== undefined
            ? constants.client_email
            : client_emaildefault
          : "",
      meeting_id:
        constants !== undefined
          ? constants.meeting_id !== undefined
            ? constants.meeting_id
            : meeting_iddefault
          : "",
      created_at:
        constants !== undefined && constants.created_at !== undefined
          ? constants.created_at
          : created_atdefault,
    };
    filterAllCompletedAppointments(body)
      .then((data) => {
        if (data.data.status) {
          dispatch(setCompletedAppointmentsPage(completedAppointmentsPage + 1));
          dispatch(setCompletedAppointmentsrowsPerPage(globalRowsPerPage));
          dispatch(setLoading(false));
          setCompletedAppointments(
            data.data.data.info.map((data) => {
              return { ...data, statusValue: "", nostatusValue: "" };
            })
          );
          dispatch(
            setAllUpcomingCount(
              JSON.parse(
                JSON.stringify(data.data.data.appointments_count.upcoming)
              )
            )
          );
          dispatch(
            setAllCancelledCount(data.data.data.appointments_count.cancelled)
          );
          dispatch(setAllNoShowCount(data.data.data.appointments_count.noshow));
          dispatch(
            setAllOngoingCount(data.data.data.appointments_count.ongoing)
          );
          dispatch(
            setAllCompletedCount(
              JSON.parse(
                JSON.stringify(data.data.data.appointments_count.completed)
              )
            )
          );
          dispatch(
            setAllRestrictedCount(data.data.data.appointments_count.restrict)
          );
          dispatch(
            setEndedEarlyCount(data.data.data.appointments_count.endearly)
          );
          setListArray(
            list.map((item) => {
              if (item.name === "Upcoming") {
                return {
                  ...item,
                  ...(item.count = data.data.data.appointments_count.upcoming),
                };
              }
              if (item.name === "Completed") {
                return {
                  ...item,
                  ...(item.count = data.data.data.appointments_count.completed),
                };
              }

              if (item.name === "Cancelled") {
                return {
                  ...item,
                  ...(item.count = data.data.data.appointments_count.cancelled),
                };
              }

              if (item.name === "No Shows") {
                return {
                  ...item,
                  ...(item.count = data.data.data.appointments_count.noshow),
                };
              }
              if (item.name === "Restriced") {
                return {
                  ...item,
                  ...(item.count = data.data.data.appointments_count.restrict),
                };
              }
              if (item.name === "Ended Early") {
                return {
                  ...item,
                  ...(item.count = data.data.data.appointments_count.endearly),
                };
              }
              if (item.name === "Ongoing") {
                return {
                  ...item,
                  ...(item.count = data.data.data.appointments_count.ongoing),
                };
              }
            })
          );
        }
      })
      .catch(() => { });
  };
  const filterListCancelledAppointments = (constants, from, value) => {
    if (from !== "filter") {
      setServiceFilterValue("");
    }
    const {
      session_type,
      screen_name,
      orderBy = "desc",
      order,
      name,
      medium_email,
      client_email,
      meeting_id,
    } = constants;
    let screen_namedefault =
      constants.screen_name == undefined
        ? searchValue !== undefined && searchValue.screen_name !== undefined
          ? searchValue.screen_name
          : ""
        : constants.screen_name;
    let namdefault =
      constants.name == undefined
        ? searchValue !== undefined && searchValue.name !== undefined
          ? searchValue.name
          : ""
        : constants.name;
    let medium_emaildefault =
      constants.medium_email == undefined
        ? searchValue !== undefined && searchValue.medium_email !== undefined
          ? searchValue.medium_email
          : ""
        : constants.medium_email;
    let client_emaildefault =
      constants.client_email == undefined
        ? searchValue !== undefined && searchValue.client_email !== undefined
          ? searchValue.client_email
          : ""
        : constants.client_email;
    let meeting_iddefault =
      constants.meeting_id == undefined
        ? searchValue !== undefined && searchValue.meeting_id !== undefined
          ? searchValue.meeting_id
          : ""
        : constants.meeting_id;
    let created_atdefault =
      constants.created_at == undefined
        ? searchValue !== undefined && searchValue.created_at !== undefined
          ? searchValue.created_at
          : ""
        : constants.created_at;
    let body = {
      orderBy,
      order,
      screen_name:
        constants !== undefined
          ? constants.screen_name !== undefined
            ? constants.screen_name
            : screen_namedefault
          : "",
      session_type:
        from === "filter"
          ? filterserviceListValue(serviceTypeList, parseInt(value))
          : constants !== undefined
            ? constants.session_type !== undefined
              ? constants.session_type
              : filterserviceListValue(
                serviceTypeList,
                parseInt(serviceFilterValue)
              )
            : "",
      // session_type:
      //   constants !== undefined
      //     ? constants.session_type !== undefined
      //       ? constants.session_type
      //       : ""
      //     : "",
      name:
        constants !== undefined
          ? constants.name !== undefined
            ? constants.name
            : namdefault
          : "",
      medium_email:
        constants !== undefined
          ? constants.medium_email !== undefined
            ? constants.medium_email
            : medium_emaildefault
          : "",
      client_email:
        constants !== undefined
          ? constants.client_email !== undefined
            ? constants.client_email
            : client_emaildefault
          : "",
      meeting_id:
        constants !== undefined
          ? constants.meeting_id !== undefined
            ? constants.meeting_id
            : meeting_iddefault
          : "",
      created_at:
        constants !== undefined && constants.created_at !== undefined
          ? constants.created_at
          : created_atdefault,
    };
    filterAllCancelleddAppointments(body)
      .then((data) => {
        if (data.data.status) {
          dispatch(setCancelledAppointmentsPage(cancelledAppointmentsPage + 1));
          dispatch(setCancelledAppointmentsrowsPerPage(globalRowsPerPage));
          dispatch(setLoading(false));
          setCanceledAppointments(
            data.data.data.info.map((data) => {
              return { ...data, statusValue: "", nostatusValue: "" };
            })
          );
          dispatch(
            setAllUpcomingCount(
              JSON.parse(
                JSON.stringify(data.data.data.appointments_count.upcoming)
              )
            )
          );
          dispatch(
            setAllCompletedCount(
              JSON.parse(
                JSON.stringify(data.data.data.appointments_count.completed)
              )
            )
          );
          dispatch(
            setAllCancelledCount(data.data.data.appointments_count.cancelled)
          );
          dispatch(setAllNoShowCount(data.data.data.appointments_count.noshow));
          dispatch(
            setAllOngoingCount(data.data.data.appointments_count.ongoing)
          );
          dispatch(
            setAllRestrictedCount(data.data.data.appointments_count.restrict)
          );
          dispatch(
            setEndedEarlyCount(data.data.data.appointments_count.endearly)
          );
          setListArray(
            list.map((item) => {
              if (item.name === "Upcoming") {
                return {
                  ...item,
                  ...(item.count = data.data.data.appointments_count.upcoming),
                };
              }
              if (item.name === "Completed") {
                return {
                  ...item,
                  ...(item.count = data.data.data.appointments_count.completed),
                };
              }

              if (item.name === "Cancelled") {
                return {
                  ...item,
                  ...(item.count = data.data.data.appointments_count.cancelled),
                };
              }

              if (item.name === "No Shows") {
                return {
                  ...item,
                  ...(item.count = data.data.data.appointments_count.noshow),
                };
              }
              if (item.name === "Restriced") {
                return {
                  ...item,
                  ...(item.count = data.data.data.appointments_count.restrict),
                };
              }
              if (item.name === "Ended Early") {
                return {
                  ...item,
                  ...(item.count = data.data.data.appointments_count.endearly),
                };
              }
              if (item.name === "Ongoing") {
                return {
                  ...item,
                  ...(item.count = data.data.data.appointments_count.ongoing),
                };
              }
            })
          );
        }
      })
      .catch(() => { });
  };
  const filterListNoShowdAppointments = (constants, from, value) => {
    if (from !== "filter") {
      setServiceFilterValue("");
    }
    const {
      session_type,
      screen_name,
      orderBy = "desc",
      order,
      name,
      medium_email,
      client_email,
      meeting_id,
    } = constants;
    let screen_namedefault =
      constants.screen_name == undefined
        ? searchValue !== undefined && searchValue.screen_name !== undefined
          ? searchValue.screen_name
          : ""
        : constants.screen_name;
    let namdefault =
      constants.name == undefined
        ? searchValue !== undefined && searchValue.name !== undefined
          ? searchValue.name
          : ""
        : constants.name;
    let medium_emaildefault =
      constants.medium_email == undefined
        ? searchValue !== undefined && searchValue.medium_email !== undefined
          ? searchValue.medium_email
          : ""
        : constants.medium_email;
    let client_emaildefault =
      constants.client_email == undefined
        ? searchValue !== undefined && searchValue.client_email !== undefined
          ? searchValue.client_email
          : ""
        : constants.client_email;
    let meeting_iddefault =
      constants.meeting_id == undefined
        ? searchValue !== undefined && searchValue.meeting_id !== undefined
          ? searchValue.meeting_id
          : ""
        : constants.meeting_id;
    let created_atdefault =
      constants.created_at == undefined
        ? searchValue !== undefined && searchValue.created_at !== undefined
          ? searchValue.created_at
          : ""
        : constants.created_at;
    let body = {
      orderBy,
      order,
      screen_name:
        constants !== undefined
          ? constants.screen_name !== undefined
            ? constants.screen_name
            : screen_namedefault
          : "",
      session_type:
        from === "filter"
          ? filterserviceListValue(serviceTypeList, parseInt(value))
          : constants !== undefined
            ? constants.session_type !== undefined
              ? constants.session_type
              : filterserviceListValue(
                serviceTypeList,
                parseInt(serviceFilterValue)
              )
            : "",
      // session_type:
      //   constants !== undefined
      //     ? constants.session_type !== undefined
      //       ? constants.session_type
      //       : ""
      //     : "",
      name:
        constants !== undefined
          ? constants.name !== undefined
            ? constants.name
            : namdefault
          : "",
      medium_email:
        constants !== undefined
          ? constants.medium_email !== undefined
            ? constants.medium_email
            : medium_emaildefault
          : "",
      client_email:
        constants !== undefined
          ? constants.client_email !== undefined
            ? constants.client_email
            : client_emaildefault
          : "",
      meeting_id:
        constants !== undefined
          ? constants.meeting_id !== undefined
            ? constants.meeting_id
            : meeting_iddefault
          : "",
      created_at:
        constants !== undefined && constants.created_at !== undefined
          ? constants.created_at
          : created_atdefault,
    };
    filterAllNoShowAppointments(body)
      .then((data) => {
        if (data.data.status) {
          dispatch(setNoShowAppointmentsPage(noshowAppointmentsPage + 1));
          dispatch(setNoShowAppointmentsrowsPerPage(globalRowsPerPage));
          dispatch(setLoading(false));

          setShowAppointments(
            data.data.data.info.map((data) => {
              return { ...data, statusValue: "", nostatusValue: "" };
            })
          );
          dispatch(
            setAllUpcomingCount(
              JSON.parse(
                JSON.stringify(data.data.data.appointments_count.upcoming)
              )
            )
          );
          dispatch(
            setAllCompletedCount(
              JSON.parse(
                JSON.stringify(data.data.data.appointments_count.completed)
              )
            )
          );
          dispatch(
            setAllCancelledCount(data.data.data.appointments_count.cancelled)
          );
          dispatch(setAllNoShowCount(data.data.data.appointments_count.noshow));
          dispatch(
            setAllOngoingCount(data.data.data.appointments_count.ongoing)
          );
          dispatch(
            setAllRestrictedCount(data.data.data.appointments_count.restrict)
          );
          dispatch(
            setEndedEarlyCount(data.data.data.appointments_count.endearly)
          );
          setListArray(
            list.map((item) => {
              if (item.name === "Upcoming") {
                return {
                  ...item,
                  ...(item.count = data.data.data.appointments_count.upcoming),
                };
              }
              if (item.name === "Completed") {
                return {
                  ...item,
                  ...(item.count = data.data.data.appointments_count.completed),
                };
              }

              if (item.name === "Cancelled") {
                return {
                  ...item,
                  ...(item.count = data.data.data.appointments_count.cancelled),
                };
              }

              if (item.name === "No Shows") {
                return {
                  ...item,
                  ...(item.count = data.data.data.appointments_count.noshow),
                };
              }
              if (item.name === "Restriced") {
                return {
                  ...item,
                  ...(item.count = data.data.data.appointments_count.restrict),
                };
              }
              if (item.name === "Ended Early") {
                return {
                  ...item,
                  ...(item.count = data.data.data.appointments_count.endearly),
                };
              }
              if (item.name === "Ongoing") {
                return {
                  ...item,
                  ...(item.count = data.data.data.appointments_count.ongoing),
                };
              }
            })
          );
        }
      })
      .catch(() => { });
  };
  const filterListRestrictedAppointments = (constants, from, value) => {
    if (from !== "filter") {
      setServiceFilterValue("");
    }
    const {
      session_type,
      screen_name,
      orderBy = "desc",
      order,
      name,
      medium_email,
      client_email,
      meeting_id,
    } = constants;
    let screen_namedefault =
      constants.screen_name == undefined
        ? searchValue !== undefined && searchValue.screen_name !== undefined
          ? searchValue.screen_name
          : ""
        : constants.screen_name;
    let namdefault =
      constants.name == undefined
        ? searchValue !== undefined && searchValue.name !== undefined
          ? searchValue.name
          : ""
        : constants.name;
    let medium_emaildefault =
      constants.medium_email == undefined
        ? searchValue !== undefined && searchValue.medium_email !== undefined
          ? searchValue.medium_email
          : ""
        : constants.medium_email;
    let client_emaildefault =
      constants.client_email == undefined
        ? searchValue !== undefined && searchValue.client_email !== undefined
          ? searchValue.client_email
          : ""
        : constants.client_email;
    let meeting_iddefault =
      constants.meeting_id == undefined
        ? searchValue !== undefined && searchValue.meeting_id !== undefined
          ? searchValue.meeting_id
          : ""
        : constants.meeting_id;
    let created_atdefault =
      constants.created_at == undefined
        ? searchValue !== undefined && searchValue.created_at !== undefined
          ? searchValue.created_at
          : ""
        : constants.created_at;
    let body = {
      orderBy,
      order,
      screen_name:
        constants !== undefined
          ? constants.screen_name !== undefined
            ? constants.screen_name
            : screen_namedefault
          : "",
      session_type:
        from === "filter"
          ? filterserviceListValue(serviceTypeList, parseInt(value))
          : constants !== undefined
            ? constants.session_type !== undefined
              ? constants.session_type
              : filterserviceListValue(
                serviceTypeList,
                parseInt(serviceFilterValue)
              )
            : "",
      // session_type:
      //   constants !== undefined
      //     ? constants.session_type !== undefined
      //       ? constants.session_type
      //       : ""
      //     : "",
      name:
        constants !== undefined
          ? constants.name !== undefined
            ? constants.name
            : namdefault
          : "",
      medium_email:
        constants !== undefined
          ? constants.medium_email !== undefined
            ? constants.medium_email
            : medium_emaildefault
          : "",
      client_email:
        constants !== undefined
          ? constants.client_email !== undefined
            ? constants.client_email
            : client_emaildefault
          : "",
      meeting_id:
        constants !== undefined
          ? constants.meeting_id !== undefined
            ? constants.meeting_id
            : meeting_iddefault
          : "",
      created_at:
        constants !== undefined && constants.created_at !== undefined
          ? constants.created_at
          : created_atdefault,
    };
    filterAllRestrictedAppointments(body)
      .then((data) => {
        if (data.data.status) {
          dispatch(
            setrestrictedAppointmentsPage(restrictedAppointmentsPage + 1)
          );
          dispatch(setrestrictedAppointmentsrowsPerPage(globalRowsPerPage));
          dispatch(setLoading(false));
          //
          setRestrictedAppontmets(
            data.data.data.info.map((data) => {
              return { ...data, statusValue: "", nostatusValue: "" };
            })
          );
          dispatch(
            setAllUpcomingCount(
              JSON.parse(
                JSON.stringify(data.data.data.appointments_count.upcoming)
              )
            )
          );
          dispatch(
            setAllCompletedCount(
              JSON.parse(
                JSON.stringify(data.data.data.appointments_count.completed)
              )
            )
          );
          dispatch(
            setAllCancelledCount(data.data.data.appointments_count.cancelled)
          );
          dispatch(setAllNoShowCount(data.data.data.appointments_count.noshow));
          dispatch(
            setAllOngoingCount(data.data.data.appointments_count.ongoing)
          );
          dispatch(
            setAllRestrictedCount(data.data.data.appointments_count.restrict)
          );
          dispatch(
            setEndedEarlyCount(data.data.data.appointments_count.endearly)
          );
          setListArray(
            list.map((item) => {
              if (item.name === "Upcoming") {
                return {
                  ...item,
                  ...(item.count = data.data.data.appointments_count.upcoming),
                };
              }
              if (item.name === "Completed") {
                return {
                  ...item,
                  ...(item.count = data.data.data.appointments_count.completed),
                };
              }

              if (item.name === "Cancelled") {
                return {
                  ...item,
                  ...(item.count = data.data.data.appointments_count.cancelled),
                };
              }

              if (item.name === "No Shows") {
                return {
                  ...item,
                  ...(item.count = data.data.data.appointments_count.noshow),
                };
              }
              if (item.name === "Restriced") {
                return {
                  ...item,
                  ...(item.count = data.data.data.appointments_count.restrict),
                };
              }
              if (item.name === "Ended Early") {
                return {
                  ...item,
                  ...(item.count = data.data.data.appointments_count.endearly),
                };
              }
              if (item.name === "Ongoing") {
                return {
                  ...item,
                  ...(item.count = data.data.data.appointments_count.ongoing),
                };
              }
            })
          );
        }
        dispatch(setLoading(false));
      })
      .catch(() => {
        dispatch(setLoading(false));
      });
  };

  const filterListEndedEarlyAppointments = (constants, from, value) => {
    if (from !== "filter") {
      setServiceFilterValue("");
    }
    const {
      session_type,
      screen_name,
      orderBy = "desc",
      order,
      name,
      medium_email,
      client_email,
      meeting_id,
    } = constants;
    let screen_namedefault =
      constants.screen_name == undefined
        ? searchValue !== undefined && searchValue.screen_name !== undefined
          ? searchValue.screen_name
          : ""
        : constants.screen_name;
    let namdefault =
      constants.name == undefined
        ? searchValue !== undefined && searchValue.name !== undefined
          ? searchValue.name
          : ""
        : constants.name;
    let medium_emaildefault =
      constants.medium_email == undefined
        ? searchValue !== undefined && searchValue.medium_email !== undefined
          ? searchValue.medium_email
          : ""
        : constants.medium_email;
    let client_emaildefault =
      constants.client_email == undefined
        ? searchValue !== undefined && searchValue.client_email !== undefined
          ? searchValue.client_email
          : ""
        : constants.client_email;
    let meeting_iddefault =
      constants.meeting_id == undefined
        ? searchValue !== undefined && searchValue.meeting_id !== undefined
          ? searchValue.meeting_id
          : ""
        : constants.meeting_id;
    let created_atdefault =
      constants.created_at == undefined
        ? searchValue !== undefined && searchValue.created_at !== undefined
          ? searchValue.created_at
          : ""
        : constants.created_at;
    let body = {
      orderBy,
      order,
      screen_name:
        constants !== undefined
          ? constants.screen_name !== undefined
            ? constants.screen_name
            : screen_namedefault
          : "",
      session_type:
        from === "filter"
          ? filterserviceListValue(serviceTypeList, parseInt(value))
          : constants !== undefined
            ? constants.session_type !== undefined
              ? constants.session_type
              : filterserviceListValue(
                serviceTypeList,
                parseInt(serviceFilterValue)
              )
            : "",
      // session_type:
      //   constants !== undefined
      //     ? constants.session_type !== undefined
      //       ? constants.session_type
      //       : ""
      //     : "",
      name:
        constants !== undefined
          ? constants.name !== undefined
            ? constants.name
            : namdefault
          : "",
      medium_email:
        constants !== undefined
          ? constants.medium_email !== undefined
            ? constants.medium_email
            : medium_emaildefault
          : "",
      client_email:
        constants !== undefined
          ? constants.client_email !== undefined
            ? constants.client_email
            : client_emaildefault
          : "",
      meeting_id:
        constants !== undefined
          ? constants.meeting_id !== undefined
            ? constants.meeting_id
            : meeting_iddefault
          : "",
      created_at:
        constants !== undefined && constants.created_at !== undefined
          ? constants.created_at
          : created_atdefault,
    };
    filterAllEndedEarlyAppointments(body)
      .then((data) => {
        if (data.data.status) {
          dispatch(
            setendedEarlyAppointmentsPage(endedEarlyAppointmentsPage + 1)
          );
          dispatch(setendedEarlyAppointmentsrowsPerPage(globalRowsPerPage));
          dispatch(setLoading(false));
          //
          setEndedEarlyAppontmets(
            data.data.data.info.map((data) => {
              return { ...data, statusValue: "", nostatusValue: "" };
            })
          );
          dispatch(
            setAllUpcomingCount(
              JSON.parse(
                JSON.stringify(data.data.data.appointments_count.upcoming)
              )
            )
          );
          dispatch(
            setAllCompletedCount(
              JSON.parse(
                JSON.stringify(data.data.data.appointments_count.completed)
              )
            )
          );
          dispatch(
            setAllCancelledCount(data.data.data.appointments_count.cancelled)
          );
          dispatch(setAllNoShowCount(data.data.data.appointments_count.noshow));
          dispatch(
            setAllOngoingCount(data.data.data.appointments_count.ongoing)
          );
          dispatch(
            setAllRestrictedCount(data.data.data.appointments_count.restrict)
          );
          dispatch(
            setEndedEarlyCount(data.data.data.appointments_count.endearly)
          );
          setListArray(
            list.map((item) => {
              if (item.name === "Upcoming") {
                return {
                  ...item,
                  ...(item.count = data.data.data.appointments_count.upcoming),
                };
              }
              if (item.name === "Completed") {
                return {
                  ...item,
                  ...(item.count = data.data.data.appointments_count.completed),
                };
              }

              if (item.name === "Cancelled") {
                return {
                  ...item,
                  ...(item.count = data.data.data.appointments_count.cancelled),
                };
              }

              if (item.name === "No Shows") {
                return {
                  ...item,
                  ...(item.count = data.data.data.appointments_count.noshow),
                };
              }
              if (item.name === "Restriced") {
                return {
                  ...item,
                  ...(item.count = data.data.data.appointments_count.restrict),
                };
              }
              if (item.name === "Ended Early") {
                return {
                  ...item,
                  ...(item.count = data.data.data.appointments_count.endearly),
                };
              }
              if (item.name === "Ongoing") {
                return {
                  ...item,
                  ...(item.count = data.data.data.appointments_count.ongoing),
                };
              }
            })
          );
        }
        dispatch(setLoading(false));
      })
      .catch(() => {
        dispatch(setLoading(false));
      });
  };
  // const filterListOngoingAppointments = (constants) => {
  //   const {
  //     session_type,
  //     screen_name,
  //     orderBy = "asc",
  //     name,
  //     medium_email,
  //     client_email,
  //     meeting_id,
  //   } = constants;
  //   let body = {
  //     session_type,
  //     screen_name,
  //     orderBy,
  //     name,
  //     medium_email,
  //     client_email,
  //     meeting_id,
  //   };
  //   filterAllOngoingAppointments(body)
  //     .then((data) => {
  //       if (data.data.status) {
  //         dispatch(setCompletedAppointmentsPage(2));
  //         dispatch(setCompletedAppointmentsrowsPerPage(10));
  //         dispatch(setLoading(false));
  //         setOngoingAppointments(
  //           data.data.data.info.map((data) => {
  //             return { ...data, statusValue: "", nostatusValue: "" };
  //           })
  //         );
  //         dispatch(
  //           setAllUpcomingCount(
  //             JSON.parse(
  //               JSON.stringify(data.data.data.appointments_count.upcoming)
  //             )
  //           )
  //         );
  //         dispatch(
  //           setAllCompletedCount(
  //             JSON.parse(
  //               JSON.stringify(data.data.data.appointments_count.completed)
  //             )
  //           )
  //         );
  //         setListArray(
  //           list.map((item) => {
  //             if (item.name === "Upcoming") {
  //               return {
  //                 ...item,
  //                 ...(item.count = data.data.data.appointments_count.upcoming),
  //               };
  //             }
  //             if (item.name === "Completed") {
  //               return {
  //                 ...item,
  //                 ...(item.count = data.data.data.appointments_count.completed),
  //               };
  //             }

  //             if (item.name === "Cancelled") {
  //               return {
  //                 ...item,
  //                 ...(item.count =  data.data.data.appointments_count.cancelled),
  //               };
  //             }

  //             if (item.name === "No Shows") {
  //               return {
  //                 ...item,
  //                 ...(item.count =  data.data.data.appointments_count.noshow),
  //               };
  //             }
  //             if (item.name === "Ongoing") {
  //               return {
  //                 ...item,
  //                 ...(item.count =  data.data.data.appointments_count.ongoing),
  //               };
  //             }
  //           })
  //         );
  //       }
  //     })
  //     .catch(() => {});
  // };

  const changeHandlerFilter = (e, filterName) => {
    // setFilterValues('screen_name')
    // setSearchValues({ screen_name: '' })
    let value = e.target.value;
    console.log(value, "value");
    setServiceFilterValue(value);
    if (filterName === "upcoming") {
      filterListUpcomingAppointments(searchValue, "filter", value);
    } else if (filterName === "completely") {
      filterListCompletedAppointments(searchValue, "filter", value);
    } else if (filterName === "cancelled") {
      filterListCancelledAppointments(searchValue, "filter", value);
    } else if (filterName === "noshows") {
      filterListNoShowdAppointments(searchValue, "filter", value);
    } else if (filterName === "restricted") {
      filterListRestrictedAppointments(searchValue, "filter", value);
    } else if (filterName === "endedearly") {
      filterListEndedEarlyAppointments(searchValue, "filter", value);
    }
    // else if (filterName === 'ongoing') {
    //   (searchValue, 'filter', value);
    // }
  };
  const filterReset = () => {
    setServiceFilterValue("");
    setFilterValues("screen_name");
    setSearchValues("");
    let data = {
      page: 1,
      rowsPerPage: 10,
      session_type: "",
      medium_name: "",
      name: "",
      medium_email: "",
      client_email: "",
      meeting_id: "",
      created_at: "",
      orderBy: "",
      order: "",
    };
    if (activeTab === 0) {
      getUpComingAppointments(data, activeTab);
    }
    if (activeTab === 1) {
      getCompletedAppointments(data, activeTab);
    }
    if (activeTab === 2) {
      getCancelledAppointments(data, activeTab);
    }
    if (activeTab === 3) {
      getNoShowsAppointments(data, activeTab);
    }
    if (activeTab === 4) {
      getRestrictedAppointments(data, activeTab);
    }
    if (activeTab === 5) {
      getEndedEarlyAppointments(data, activeTab);
    }
    if (activeTab === 6) {
      getOngoingAppointments(data, activeTab);
    }
  };

  useEffect(() => {
    Pusher.logToConsole = true;
    var pusher = new Pusher("823ab72ed6ecd563b8c8", {
      cluster: "ap2",
    });

    var channel = pusher.subscribe("zoom-link");
    channel.bind("App\\Events\\ZoomLinkUpdate", function (data) {

      if (data.appointment_id && data.meeting_link) {
        setmeetingLinkRegenerated(data)
      }
    });
  }, []);

  useEffect(() => {
    console.log(upcomingAppointment, "upcomingAppointment")
    if (meetingLinkRegenerated.appointment_id && meetingLinkRegenerated.meeting_link) {
      let mapin = upcomingAppointment.map((info) => {
        if (info.id == meetingLinkRegenerated.appointment_id) {
          return {
            ...info,
            meeting_link: meetingLinkRegenerated.meeting_link,
          };
        } else {
          return {
            ...info,
          };
        }
      });
      console.log(mapin, "mapin");
      setUpcomingAppointments(mapin);
    }

  }, [meetingLinkRegenerated])
  return (
    <div className="all-medium-page">
      <div className="middle-screen-name time-zone-area font-size-22 mt-2 mb-2">
        {" "}
        <select
          onChange={changeTimeZoneHandler}
          className="select-box-timezone pointer"
        >
          <option disabled selected value="">
            Change Timezone
          </option>
          {timezoneData.map((item) => (
            <option value={item.id}>{item.front_name}</option>
          ))}
        </select>
        <b>
          Timezone: {timezone !== undefined && timezone}&nbsp; { }
        </b>
      </div>
      <Tabs active={activeTab} list={listArray} setActiveTab={setActiveTab} />
      <ul className="tabs-content">
        {console.log(upcomingAppointment, "upcomingAppointment")}
        <TabPanel id={0} activeTab={activeTab}>
          <Upcoming
            upcomingAppointments={upcomingAppointment}
            changeHandler={changeHandler}
            filterListAppointments={filterListUpcomingAppointments}
            filterReset={filterReset}
            getPaginationData={(data) => getPaginationData(data, activeTab)}
            setFilterValues={setFilterValues}
            setSearchValues={setSearchValues}
            getAllAppointmentsList={getUpComingAppointments}
            activeTab={activeTab}
            upcomingAppointmentsPage={upcomingAppointmentsPage}
            upcomingAppointmentsrowsPerPage={upcomingAppointmentsrowsPerPage}
            searchValue={searchValue}
            filterValues={filterValues}
            serviceTypeList={serviceTypeList}
            changeHandlerFilter={changeHandlerFilter}
            serviceFilterValue={serviceFilterValue}
            setSortingNameOrderBy={setSortingNameOrderBy}
            setSortingTabType={setSortingTabType}
          />
        </TabPanel>

        <TabPanel id={1} activeTab={activeTab}>
          <Completed
            completedAppointments={completedAppointment}
            changeHandler={changeHandler}
            filterReset={filterReset}
            filterListAppointments={filterListCompletedAppointments}
            getPaginationData={(data) => getPaginationData(data, activeTab)}
            setFilterValues={setFilterValues}
            setSearchValues={setSearchValues}
            getAllAppointmentsList={getCompletedAppointments}
            activeTab={activeTab}
            completedAppointmentsPage={completedAppointmentsPage}
            completedAppointmentsrowsPerPage={completedAppointmentsrowsPerPage}
            searchValue={searchValue}
            filterValues={filterValues}
            serviceTypeList={serviceTypeList}
            changeHandlerFilter={changeHandlerFilter}
            serviceFilterValue={serviceFilterValue}
            setSortingNameOrderBy={setSortingNameOrderBy}
            setSortingTabType={setSortingTabType}
          />
        </TabPanel>
        <TabPanel id={2} activeTab={activeTab}>
          <Cancelled
            canceledAppointment={canceledAppointment}
            changeHandler={changeHandler}
            filterReset={filterReset}
            filterListAppointments={filterListCancelledAppointments}
            getPaginationData={(data) => getPaginationData(data, activeTab)}
            setFilterValues={setFilterValues}
            setSearchValues={setSearchValues}
            cancelledAppointmentsPage={cancelledAppointmentsPage}
            cancelledAppointmentsrowsPerPage={cancelledAppointmentsrowsPerPage}
            searchValue={searchValue}
            filterValues={filterValues}
            serviceTypeList={serviceTypeList}
            changeHandlerFilter={changeHandlerFilter}
            serviceFilterValue={serviceFilterValue}
            setSortingNameOrderBy={setSortingNameOrderBy}
            setSortingTabType={setSortingTabType}
            getAllAppointmentsList={getCancelledAppointments}
            activeTab={activeTab}
          />
        </TabPanel>
        <TabPanel id={3} activeTab={activeTab}>
          <NoShows
            showAppointment={showAppointment}
            changeHandler={changeHandler}
            filterReset={filterReset}
            filterListAppointments={filterListNoShowdAppointments}
            getPaginationData={(data) => getPaginationData(data, activeTab)}
            setFilterValues={setFilterValues}
            setSearchValues={setSearchValues}
            noshowAppointmentsPage={noshowAppointmentsPage}
            noshowAppointmentsrowsPerPage={noshowAppointmentsrowsPerPage}
            searchValue={searchValue}
            filterValues={filterValues}
            serviceTypeList={serviceTypeList}
            changeHandlerFilter={changeHandlerFilter}
            serviceFilterValue={serviceFilterValue}
            setSortingNameOrderBy={setSortingNameOrderBy}
            setSortingTabType={setSortingTabType}
            getAllAppointmentsList={getNoShowsAppointments}
            activeTab={activeTab}
          />
        </TabPanel>
        <TabPanel id={4} activeTab={activeTab}>
          <RestrictedAppointments
            showAppointment={restrictedAppontmets}
            changeHandler={changeHandler}
            filterReset={filterReset}
            filterListAppointments={filterListRestrictedAppointments}
            getPaginationData={(data) => getPaginationData(data, activeTab)}
            setFilterValues={setFilterValues}
            setSearchValues={setSearchValues}
            restrictedAppointmentsPage={restrictedAppointmentsPage}
            restrictedAppointmentsrowsPerPage={
              restrictedAppointmentsrowsPerPage
            }
            searchValue={searchValue}
            filterValues={filterValues}
            serviceTypeList={serviceTypeList}
            changeHandlerFilter={changeHandlerFilter}
            serviceFilterValue={serviceFilterValue}
            setSortingNameOrderBy={setSortingNameOrderBy}
            setSortingTabType={setSortingTabType}
            getAllAppointmentsList={getRestrictedAppointments}
            activeTab={activeTab}
          />
        </TabPanel>
        <TabPanel id={5} activeTab={activeTab}>
          <EndedEarlyAppointments
            showAppointment={endedEarlyAppontmets}
            changeHandler={changeHandler}
            filterListAppointments={filterListEndedEarlyAppointments}
            filterReset={filterReset}
            getPaginationData={(data) => getPaginationData(data, activeTab)}
            setFilterValues={setFilterValues}
            setSearchValues={setSearchValues}
            endedEarlyAppointmentsPage={endedEarlyAppointmentsPage}
            endedEarlyAppointmentsrowsPerPage={
              endedEarlyAppointmentsrowsPerPage
            }
            searchValue={searchValue}
            filterValues={filterValues}
            serviceTypeList={serviceTypeList}
            changeHandlerFilter={changeHandlerFilter}
            serviceFilterValue={serviceFilterValue}
            setSortingNameOrderBy={setSortingNameOrderBy}
            setSortingTabType={setSortingTabType}
            getAllAppointmentsList={getEndedEarlyAppointments}
            activeTab={activeTab}
          />
        </TabPanel>

        <TabPanel id={6} activeTab={activeTab}>
          <Ongoing
            showOngoingAppointments={showOngoingAppointments}
            changeHandler={changeHandler}
            filterReset={filterReset}
            filterListAppointments={() => { }}
            getPaginationData={(data) => getPaginationData(data, activeTab)}
            setFilterValues={setFilterValues}
            setSearchValues={setSearchValues}
            ongoingAppointmentsPage={ongoingAppointmentsPage}
            ongoingAppointmentsrowsPerPage={ongoingAppointmentsrowsPerPage}
            searchValue={searchValue}
            filterValues={filterValues}
            serviceTypeList={serviceTypeList}
            changeHandlerFilter={changeHandlerFilter}
            serviceFilterValue={serviceFilterValue}
            setSortingNameOrderBy={setSortingNameOrderBy}
            setSortingTabType={setSortingTabType}
          />
        </TabPanel>
      </ul>
      {/* <Pagination  rowsPerPageOptions={[5, 10, 25]}  count={100} getPaginationData={getPaginationData} /> */}
    </div>
  );
}
