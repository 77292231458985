import React, { useEffect, useState } from 'react'
import { onDemandHistory, deleteonDemandHistory } from '../../../services/admin';
import { toast } from "react-toastify";
import Button from "../../../components/button";
import Pusher from 'pusher-js';

export default function OnDemandHistory() {
    const listHeading = ["User ID", 'Screen Name', 'Email', ' Event', 'Date Time Added', 'Date Out', 'Selected Preference', 'Paired With',"Appointment ID", 'Action'];
    const [onDemandHistoryList, setOnDemandHistoryList] = useState([])

    useEffect(() => {
        onDemandHistory().then((data) => {
            setOnDemandHistoryList(data.data.data)
        }).catch(() => { })
    }, [])
    const handleDelete = (id) => {
        deleteonDemandHistory(id)
            .then((data) => {
                // Remove the deleted item from the state
                console.log(data.data)
                if (data.data.data == true) {
                    toast.success(data.data.success, {
                      position: "top-center",
                    });
                    setOnDemandHistoryList(prevList => 
                        prevList.filter(item => item.id !== id)
                    );
                  } else {
                    toast.error("Something went wrong", {
                      position: "top-center",
                    });
                  }
            })
            .catch((error) => {
                console.error('Error deleting record:', error);
                toast.error("Something went wrong", {
                    position: "top-center",
                  });
            });
    };
    useEffect(() => {

        Pusher.logToConsole = true;
        var pusher = new Pusher("823ab72ed6ecd563b8c8", {
            cluster: "ap2",
        });

        var channel = pusher.subscribe("on-demand-queue");
        channel.bind("App\\Events\\OnDemandQueue", function (data) {
            if (data.users.length) {
                onDemandHistory().then((data) => {
                    setOnDemandHistoryList(data.data.data)
                }).catch(() => { })
            }


        });

    }, [])
    return (
        <div className="table-mideum-table tableclander">
            <table>
                <tbody>
                    <tr>
                        {listHeading.map((item) => (
                            <th>{item}</th>
                        ))}
                    </tr>


                    {onDemandHistoryList.length > 0 && onDemandHistoryList.map((item) => <tr>
                        <td className="padd-15">{item.user_id}</td>
                        
                        <td className="padd-15">{item.user_name}</td>
                        <td className="padd-15">{item.email}</td>
                        <td className="padd-15">{item.event_name}</td>
                        <td className="padd-15">{item.date_added}</td>
                        <td className="padd-15">{item.date_out}</td>
                        <td className="padd-15">{item.selected_preference}</td>
                        <td className="padd-15">{item.paired_with ? item.paired_with : "-"}</td>
                        <td className="padd-15">{item.appointment_id ? item.appointment_id : "-"}</td>
                        <td className="padd-15">
                        <Button
                            status={0}
                            onClick={(e) => handleDelete(item.id)}
                            type="button"
                            className={`resend-btn w-130`}
                            title="Delete"
                          />
                       
                        </td>
                    </tr>)}
                </tbody>
            </table>
        </div>
    )
}


