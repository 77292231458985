import React, { useState } from 'react'
import AllFeedback from './allfeedback'
import Tabs from '../../../components/tabs';
import TabPanel from '../../../components/tabs/tabpanel';
import PracticesFeedback from './practicefeedbacks';
import CoachingFeedback from './coachingfeedbacks';
import GroupCoachingFeedback from './groupcoachingfeedbacks';
import OnDemandFeedback from './ondemandfeedbacks';
const list = [
  { id: 1, name: "Client Feedback", },
  { id: 2, name: "Practice", },
  { id: 3, name: "Coaching", },
  { id: 4, name: "Group Coaching", },
  { id: 5, name: "On Demand Practice", },


];
export default function Feedback() {

  const [activeTab, setActiveTab] = useState(0);
  return (
    <div className="all-medium-page">

      <Tabs active={activeTab} list={list} setActiveTab={setActiveTab} />
      <TabPanel id={0} activeTab={activeTab}>
        <AllFeedback />
      </TabPanel>
      <TabPanel id={1} activeTab={activeTab}>
        <PracticesFeedback />
      </TabPanel>
      <TabPanel id={2} activeTab={activeTab}>
        <CoachingFeedback />
      </TabPanel>
      <TabPanel id={3} activeTab={activeTab}>
        <GroupCoachingFeedback />
      </TabPanel>
      <TabPanel id={4} activeTab={activeTab}>
          <OnDemandFeedback />
        </TabPanel>
    </div>
  )
}
