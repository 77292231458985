import React, { useEffect, useState } from "react";
import "./backfill.css";
import { getMediumBackfillSettings, saveMediumBackfillSettings } from "../../../services/medium";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

export default function BackFill() {



    const { id } = useSelector(
        (state) => state.user.user
    );

    const [service_type, setservice_type] = useState(['donated']);
    const [is_automatic, setis_automatic] = useState(false);
    const [notification_method, setnotification_method] = useState('');
    const [appointment_time, setappointment_time] = useState('');
    const [user_id, setuser_id] = useState('');



    useEffect(() => {
        getData()
    }, [])

    const getData = () => {
        getMediumBackfillSettings().then((data) => {
            if (data.data.success == "Request completed successfully") {
                if (data.data.data) {
                    let info = data.data.data;
                    setservice_type(info.service_type)
                    setis_automatic(info.is_automatic)
                    setnotification_method(info.notification_method)
                    setappointment_time(info.appointment_time)
                    setuser_id(info.id);
                }
            }
        })
    }
    const handleSubmit = () => {

        let body = {
            medium_id: id,
            service_type: service_type,
            is_automatic: is_automatic == "true" ? true : false,
            notification_method: notification_method,
            appointment_time: appointment_time,
        }
        if (user_id !== "") {

            Object.assign(body, { id: user_id, });
        }
        saveMediumBackfillSettings(body).then((data) => {
            console.log(data.data,"data.data")
            if (data.data.success) {
                setuser_id(data.data.data.id)
                toast.success("Settings updated succesfully", {
                    position: "top-center",
                });
            } else {
                toast.error("Something went wrong", {
                    position: "top-center",
                });
            }
        }).catch(()=>{
            toast.error("Something went wrong", {
                position: "top-center",
            });
        })
    }

    const serviceTypeHandler = (e) => {
        let checked = e.target.checked;
        let value = e.target.value;
        let array = [...service_type]
        if (checked) {
            array.push(value);
            setservice_type(array)
        }
        else {
            let newArray = array.filter(e => e !== value)
            setservice_type(newArray)
        }
        // console.log("array", array)

        // console.log("checked", checked)
        // console.log("value", value)
    }

    return (
        <div className="backfill-wrapper">

            <div className="backfill-container">
                <div className="backfill-left-side ">
                    <div className="d-flex margin-60px flex-wrap ">
                        <h4 className="width-260-px">Triggering Services</h4>{" "}
                        <div>
                            <div className="prvacy-box">
                                {console.log(service_type,"service_type")}
                                <input
                                    // defaultChecked={true}
                                    onChange={(e) => serviceTypeHandler(e)}
                                    // onBlur={handleBlur}
                                    defaultChecked={service_type.includes("donated") ? true : false}
                                    value="donated"
                                    id="donated"
                                    type="checkbox"
                                    name="donated"
                                // disabled
                                // checked={values.agree_to_legal_terms_and_conditionss ? 'checked' : ''}
                                />

                                <label htmlFor="donated" className="width-unset">
                                    Donated<i>*</i>
                                </label>
                            </div>
                            <div className="prvacy-box">
                                <input
                                    // onChange={handleChange}
                                    // onBlur={handleBlur}
                                    onChange={(e) => serviceTypeHandler(e)}
                                    defaultChecked={service_type.includes("paid") ? true : false}
                                    value="paid"
                                    id="paid"
                                    type="checkbox"
                                    name="paid"
                                // disabled
                                // checked={values.agree_to_legal_terms_and_conditionss ? 'checked' : ''}
                                />

                                <label htmlFor="paid" className="width-unset">
                                    Paid
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex margin-60px flex-wrap ">
                        <h4 className="width-260-px">Appointment Times </h4>{" "}
                        <div>
                            <div className="prvacy-box">
                                <input
                                    // onChange={handleChange}
                                    // onBlur={handleBlur}
                                    onChange={(e) => setappointment_time(e.target.value)}
                                    checked={appointment_time == "exact"}
                                    value="exact"
                                    id="Exact-match-only"
                                    type="radio"
                                    name="Appointment-Times"
                                // disabled
                                // checked={values.agree_to_legal_terms_and_conditionss ? 'checked' : ''}
                                />

                                <label htmlFor="Exact-match-only" className="width-unset">
                                    Exact match only
                                </label>
                            </div>
                            <div className="prvacy-box">
                                <input
                                    // onChange={handleChange}
                                    // onBlur={handleBlur}
                                    onChange={(e) => setappointment_time(e.target.value)}
                                    checked={appointment_time == "any"}
                                    value="any"
                                    id="Any-that-fit-donated-schedule"
                                    type="radio"
                                    name="Appointment-Times"
                                // disabled
                                // checked={values.agree_to_legal_terms_and_conditionss ? 'checked' : ''}
                                />

                                <label htmlFor="Any-that-fit-donated-schedule" className="width-unset">
                                    Any that fit donated schedule<i>*</i>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="backfill-right-side ">
                    <div className="d-flex margin-60px flex-wrap ">
                        <h4 className="width-260-px">Automatic</h4>{" "}
                        <div>
                            <div className="prvacy-box">
                                <input
                                    // onChange={handleChange}
                                    // onBlur={handleBlur}
                                    onChange={(e) => setis_automatic(e.target.value)}
                                    checked={is_automatic}
                                    value={true}
                                    id="AutomaticYes"
                                    type="radio"
                                    name="Automatic"
                                // disabled
                                // checked={values.agree_to_legal_terms_and_conditionss ? 'checked' : ''}
                                />

                                <label htmlFor="AutomaticYes" className="width-unset">
                                    Yes
                                </label>
                            </div>
                            <div className="prvacy-box">
                                <input
                                    // defaultChecked={true}
                                    // onChange={handleChange}
                                    // onBlur={handleBlur}
                                    onChange={(e) => setis_automatic(e.target.value)}
                                    checked={!is_automatic}
                                    value={false}
                                    id="AutomaticNo"
                                    type="radio"
                                    name="Automatic"
                                // disabled
                                // checked={values.agree_to_legal_terms_and_conditionss ? 'checked' : ''}
                                />

                                <label htmlFor="AutomaticNo" className="width-unset">
                                    No<i>*</i>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex margin-60px flex-wrap ">
                        <h4 className="width-260-px">Notification Method </h4>{" "}
                        <div>
                            <div className="prvacy-box">
                                <input
                                    // onChange={handleChange}
                                    // onBlur={handleBlur}
                                    onChange={(e) => setnotification_method(e.target.value)}
                                    checked={notification_method == "email"}
                                    value="email"
                                    id="Email-Only-1"
                                    type="radio"
                                    name="Email-Only"
                                // disabled
                                // checked={values.agree_to_legal_terms_and_conditionss ? 'checked' : ''}
                                />

                                <label htmlFor="Email-Only-1" className="width-unset">
                                    Email Only
                                </label>
                            </div>
                            <div className="prvacy-box">
                                <input
                                    // onChange={handleChange}
                                    // onBlur={handleBlur}
                                    onChange={(e) => setnotification_method(e.target.value)}
                                    checked={notification_method == "email_sms"}
                                    value="email_sms"
                                    id="Email-Only-2"
                                    type="radio"
                                    name="Email-Only"
                                // disabled
                                // checked={values.agree_to_legal_terms_and_conditionss ? 'checked' : ''}
                                />

                                <label htmlFor="Email-Only-2" className="width-unset">
                                    Email & Text (U.S. & CAN only)<i>*</i>
                                </label>
                            </div>
                            <div className="prvacy-box">
                                <input
                                    // onChange={handleChange}
                                    // onBlur={handleBlur}
                                    onChange={(e) => setnotification_method(e.target.value)}
                                    checked={notification_method == "email_whatsapp"}
                                    value="email_whatsapp"
                                    id="Email-Only-3"
                                    type="radio"
                                    name="Email-Only"
                                // disabled
                                // checked={values.agree_to_legal_terms_and_conditionss ? 'checked' : ''}
                                />

                                <label htmlFor="Email-Only-3" className="width-unset">
                                    Email & WhatsApp (all countries)<i>*</i>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="d-flex align-items-center justify-content-center mt-5">

                <div onClick={handleSubmit} className="font-size-22 width-350-px white-border pointer indexstyles__StyledButton-sc-83qv1q-0 jdbotF sc-1qeub3n-4  d-flex align-items-center justify-content-center">
                    Submit
                </div>
            </div>
        </div>
    );
}
