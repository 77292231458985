import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../../components/button";
import Pagination from "../../../../components/tablePagination";
import CopyComponent from "../../../../components/copyComponent";
import CsvDownload from "../../../../components/csvdownload";
import CsvDownloadAll from "../../../../components/csvdownloadAll";
import Search from "../../../../components/search";
import Sorting from "../../../../components/sorting";
// import UserStatus from "../../../../components/userstatus";
import { filterList } from "../data";
import { fireFilter } from "../functions";
import InfoPopupclient from "../subcomponent";
import { toast } from "react-toastify";
import ToggleTestUser from "../../Subcomponents/toogletestuser";
import { setAllVolunteerClientsPage, setAllVolunteerClientsrowsPerPage, setPathArray } from "../../../../reducer/adminSlice";

const headers = [
  { label: "Screen Name", key: "screen_name" },
  { label: "Client Name", key: "client_name" },
  { label: "Email", key: "email" },
  { label: "Created", key: "created_at" },

];

export default function VolunteerClients(props) {
  const navigate = useNavigate();
  const {
    clients,
    onClickHandler,
    updateUser,
    resendEmail,
    filterReset,
    filterListClients,
    getPaginationData,
    setFilterValues,
    setSearchValues,
    searchValue,
    filterValues,
    showOprater,
    setShowOprater,
    setSortingNameOrderBy,
    setSortingTabType,
    allVolunteerClientsPage,
    allVolunteerClientsrowsPerPage,
    filterOprater,
    setFilterOprater
  } = props;

  const dispatch = useDispatch()
  const [searchParams] = useSearchParams();
  const page = searchParams.get('page');
  const rowperpage = searchParams.get('rowperpage');

  useEffect(()=>{
    if (page !== null) {
      
      dispatch(setAllVolunteerClientsPage(page));
    }
    if (rowperpage !== null) {
      dispatch(setAllVolunteerClientsrowsPerPage(rowperpage));
    }
  },[])
  let info = JSON.parse(localStorage.getItem('pagination'))
  const {
    allVolunteerClientsCount
  } = useSelector((state) => state.users);
  
  const [active, setActive] = useState([]);

  const navigateUser = (id) => {
    dispatch(setPathArray(id.id));
    window.renderFirst = false;
    navigate(`/view/${id.id}?view=3&search=${filterValues}&searchName=${Object.values(searchValue)}&page=${info.page}&rowperpage=${info.rowsPerPage}&searchOprator=${showOprater}`);
  };
  const navigateEdit = (e, id) => {
    window.renderFirst = false
    if (e.target.className == "capitalize") {
      navigate(`/edit/${id.id}?view=3&search=${filterValues}&searchName=${Object.values(searchValue)}&page=${info.page}&rowperpage=${info.rowsPerPage}&searchOprator=${showOprater}`);
   }else{

   }
  };
  const openMoreInfo = (e, info) => {
    e.stopPropagation();
    // console.log(info,"info")
    let length = Object.keys(info.appointment_counts).length;
    console.log("admin info", info.appointment_counts, length);
    if (length !== 0) {

      // let array = [];
      // Object.values(info.appointment_counts).map((item) => {
      //     // array.push({heading: info:item});
      //   });

      setActive(info.appointment_counts);
      // console.log(array,"ayfdsg")
      window.loadModal2("#InfoPopupclient", "show");
    }
    else {
      toast.error("Nothing to display", { position: "top-center" })
    }
    return;
  };
  return (
    <>
      <div className="d-flex justify-content-end gap-10 flex-wrap flex-column-admin-client">
        <Search callApi={filterListClients} list={filterList} searchValue={searchValue} setSearchValues={setSearchValues} setFilterValues={setFilterValues} 
          filterValues={filterValues} isDatepickerShow={1} isOperator={1} setFilterOprater={setFilterOprater} showOprater={showOprater}
          setShowOprater={setShowOprater} filterOprater={filterOprater}/>
          {/* <Button
          status={0} 
           type="button"
           onClick={filterReset}
           className={`resend-btn  width-80px  reset-btn-123`}
           title="Reset"
          /> */}
      </div>
      <CsvDownloadAll
        href={`${process.env.REACT_APP_API_URL_EXPORT}` + 'export-client-list'}
      //  data={fireFilter(clients)} headers={headers}filename={'outreachclients'}
      />
      <CsvDownload
        href={`${process.env.REACT_APP_API_URL_EXPORT}` + 'export-client-list?client_type=volunteer'}
      //  data={fireFilter(clients)} headers={headers}filename={'outreachclients'}
      />
      <div className="table-box-medium">
        <table className="caseTable">
          <tbody>
            <tr className="dark">
              <th>&nbsp;</th>
              <th>Screen Name</th>
              <th > <div className="d-flex align-items-center"> <span>Client Name </span> <Sorting callApi={filterListClients} shortedName={"client_name"} tableName={"client"}
               setSortingNameOrderBy={setSortingNameOrderBy}
               setSortingTabType={setSortingTabType}
               tab={4}
              /></div></th>

              <th>Email</th>
              <th>Test User</th>
              <th>Referral Code</th>
              <th > <div className="d-flex align-items-center"> <span>Created </span> <Sorting callApi={filterListClients} shortedName={"created_at_order_by"} tableName={"client"}
               setSortingNameOrderBy={setSortingNameOrderBy}
               setSortingTabType={setSortingTabType}
               tab={4}
              /></div></th>
              <th>Volunteer Date</th>
              <th>Activation email</th>
              {/* <th>Online Status</th> */}
              {/* <th>Action</th> */}
            </tr>
            {clients.length > 0 ? (
              clients.map((client) => (
                <tr
                  onClick={(e) => navigateEdit(e, client)}
                  className="innr pointer"
                >
                  <td>
                    {" "}
                    <img src="/images/undraw_profile.svg" className="profile-image-avatar" alt="avatar" />
                  </td>
                  <td>
                    {/* <input type="checkbox" id="two" />
                    <label htmlFor="two"></label> */}

                    <p className="capitalize">
                      {client.screen_name}
                      {client.comments && (<b  style={{
                              fontSize: '22px',
                            }}>*</b>)}
                    </p>
                  </td>
                  <td>
                    {/* <input type="checkbox" id="two" />
                    <label htmlFor="two"></label> */}

                    <p className="capitalize">
                      {client.first_name}&nbsp;{client.last_name}
                    </p>
                  </td>
                  <td>{client.email} <CopyComponent value={client.email} /></td>
                  <td> <ToggleTestUser
                    clientId={client.id}
                    initialStatus={client.test_user === 1} // Set initial toggle state
                    onStatusChange={(id, newStatus) => {
                      console.log(`Client ${id} status changed to ${newStatus ? 'on' : 'off'}`);
                      // Additional handling if necessary
                    }}
                  /></td>
                  <td>{client.referralCode}</td>
                  <td>
                    {client.created_at}
                  </td>
                  <td>{client.voluntree_date}</td>
                  <td>
                    <Button
                      status={client.resent_status}
                      // onClick={(e) => resendEmail(e, client.id, "volunteer")}
                      type="button"
                      className="resend-btn"
                      title="Resend"
                    />
                  </td>
                  {/* <td>
                    <UserStatus status={client.is_available} />
                  </td> */}
                  {/* <td className="dott">
                    <img
                      onClick={(e) => onClickHandler(e, client.id, "volunteer")}
                      src="/images/Unionblck.png"
                      className="padding-10"
                      alt="unblock"
                    />

                    {client.client_status && (
                      <div className="approve-btn-1 approve-btn z-index-1">
                        <span
                          onClick={(e) => {
                            e.stopPropagation();
                            navigateUser(client);
                          }}
                        >
                          View
                        </span>
                        <span
                          onClick={(e) => {
                            e.stopPropagation();
                            updateUser(
                              client,
                              parseInt(client.status) === 4 ? 1 : 4,
                              "freeclient",
                              ""
                            );
                          }}
                        >
                          {parseInt(client.status) === 4
                            ? "UnRestrict"
                            : "Restrict"}
                        </span>

                        <span
                          onClick={(e) => {
                            e.stopPropagation();
                            updateUser(client, 3, "delete", "");
                          }}
                        >
                          Delete
                        </span>
                        <span
                          onClick={(e) => openMoreInfo(e, client)}
                        >
                          Info
                        </span>
                      </div>
                    )}
                  </td> */}
                  {/* <td>
                    <Link to={`/view/${client.id}`}>
                      <img src="../images/blue-e.png" alt="view profile" />
                      View Profile
                    </Link>
                  </td> */}
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={5} className="text-center">
                  No Clients Found
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <Pagination rowsPerPageOptions={[5, 10, 25]} count={allVolunteerClientsCount} activeTab="volunteerclient" getPaginationData={getPaginationData}
         allVolunteerClientsPage={allVolunteerClientsPage}
         allVolunteerClientsrowsPerPage={allVolunteerClientsrowsPerPage}
        />
        <InfoPopupclient feedBackinfo={active} />
      </div>
    </>
  );
}