import React, { useEffect, useState } from 'react'
import { getAllOnDemandEvents } from '../../../services/admin';
import Pusher from 'pusher-js';

export default function LiveOnDemandEvents() {
    const listHeading = ["User ID", 'Screen Name', 'Email', ' Event', 'Date Time Added'];
    const [transactionsList, settransactionsList] = useState([])


    useEffect(() => {
        getAllOnDemandEvents().then((data) => {
            settransactionsList(data.data.data)
        }).catch(() => { })
    }, [])
    useEffect(() => {

        Pusher.logToConsole = true;
        var pusher = new Pusher("823ab72ed6ecd563b8c8", {
            cluster: "ap2",
        });

        var channel = pusher.subscribe("on-demand-queue");
        channel.bind("App\\Events\\OnDemandQueue", function (data) {
            if (data.users.length) {
                getAllOnDemandEvents().then((data) => {
                    settransactionsList(data.data.data)
                }).catch(() => { })
            }


        });

    }, [])
    return (
        <div className="table-mideum-table tableclander">
            <table>
                <tbody>
                    <tr>
                        {listHeading.map((item) => (
                            <th>{item}</th>
                        ))}
                    </tr>


                    {transactionsList.length > 0 && transactionsList.map((item) => <tr>
                        <td className="padd-15">{item.id}</td>
                        {/* <td className="padd-15">{item.medium_name}</td> */}
                        {/* <td className="padd-15">{item.client_name} {item.client_comment === true && (<b  style={{
                            fontSize: '22px',
                          }}>*</b>) }</td>
            <td className="padd-15">{item.date}</td>
            <td className="padd-15">{item.session_date_time}</td>
            <td className="padd-15">{item.method}</td>
            <td className="padd-15 color-d5a67b ">{item.payment_status === 1 ? 'Completed' : 'Pending'}</td>
            <td className="padd-15">{item.session_name}</td>
            <td className="padd-15">{item.amount}</td> */}
                        <td className="padd-15">{item.screen_name}</td>
                        <td className="padd-15">{item.email}</td>
                        <td className="padd-15">{item.title !== undefined && item.title}</td>
                        <td className="padd-15">{item.date_time_added !== undefined && item.date_time_added}</td>




                    </tr>)}


                    {/* ))} */}
                </tbody>
            </table>
        </div>
    )
}


