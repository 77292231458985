import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import CopyComponent from "../../../../components/copyComponent";
import CsvDownload from "../../../../components/csvdownload";
import Filter from "../../../../components/filter";
import Search from "../../../../components/search";
import Sorting from "../../../../components/sorting";
import Toggle from "../../../../components/toggle";
import UserStatus from "../../../../components/userstatus";
import Pagination from "../../../../components/tablePagination";
import { getMediumRole } from "../../../../utils/utils";
import Checkbox from "../../Subcomponents/checkbox";
import { filterList, bulkList } from "../data";
// deleteAllUserFeedbacks
import { fireFilter } from "../functions";
import { deleteAllUserFeedbacks } from "../../../../services/admin";
import { toast } from "react-toastify";
import ToggleTestUser from "../../Subcomponents/toogletestuser";
import { setPathArray, setProfessionalPage, setProfessionalrowsPerPage } from "../../../../reducer/adminSlice";
import Button from "../../../../components/button";

const headers = [
  { label: "Screen Name", key: "screen_name" },
  { label: "Email", key: "email" },
  { label: "Approved Role", key: "approved_role" },
  { label: "Online status", key: "is_available" },
  { label: "Enable/Disable", key: "is_enabled" },
];

export default function Professional(props) {
  const {
    professionals,
    updateUser,
    onClickHandler,
    filterListMediums,
    bulkActions,
    getPaginationData,
    professionalPage,
    professionalrowsPerPage,
    setFilterValues,
    setSearchValues,
    searchValue,
    filterValues,
    filterReset,
  } = props;

  const navigate = useNavigate();
  const [checked, setChecked] = useState(false);
  const [selected, setSelected] = useState([]);

  const { allProfessionalsCount } = useSelector((state) => state.users);

  const dispatch = useDispatch()
  const [searchParams] = useSearchParams();
  const page = searchParams.get('page');
  const rowperpage = searchParams.get('rowperpage');

  useEffect(() => {
    if (page !== null) {

      dispatch(setProfessionalPage(page));
    }
    if (rowperpage !== null) {
      dispatch(setProfessionalrowsPerPage(rowperpage));
    }
  }, [])
  let info = JSON.parse(localStorage.getItem('pagination'))
  const navigateUser = (e, id) => {
    window.renderFirst = false;
    e.stopPropagation();
    dispatch(setPathArray(id.id));
    navigate(`/profile-view/${id.id}?view=3&search=${filterValues}&searchName=${Object.values(searchValue)}&page=${info.page}&rowperpage=${info.rowsPerPage}`);
  };
  const publicViewHandler = (e, id) => {
    window.renderFirst = false
    e.stopPropagation();
    dispatch(setPathArray(id.id));
    navigate(`/public-view/${id.id}?view=3&search=${filterValues}&searchName=${Object.values(searchValue)}&page=${info.page}&rowperpage=${info.rowsPerPage}`);
  };
  const navigateEdit = (e, id) => {

    window.renderFirst = false
    if (e.target.className == "capitalize") {
      navigate(`/profile-edit/${id.id}?view=3&search=${filterValues}&searchName=${Object.values(searchValue)}&page=${info.page}&rowperpage=${info.rowsPerPage}`);
    } else {
    }
  };

  const checkBoxChangeHandler = (e) => {
    setChecked(e.target.checked);
    if (e.target.checked) {
      let result = professionals.map((a) => a.id);
      setSelected(result);
    } else {
      setSelected([]);
    }
  };

  // const getPaginationData = (item) => {
  //   console.log('paginationdata',item.from);
  //   getFromToData(item)
  //  };

  const changeHandler = (e) => {
    if (e.target.value === "Decline") {
      bulkActions(2, selected);
    }
    if (e.target.value === "Delete") {
      bulkActions(3, selected);
    }
  };
  const deleteFeedBack = (e, prof) => {
    e.stopPropagation();
    let body = {
      medium_id: prof.id,
    };
    deleteAllUserFeedbacks(body)
      .then((data) => {
        if (data.data.status) {
          toast.success("Feedbacks Deleted Successfully", {
            position: "top-center",
          });
        }
      })
      .catch(() => { });
  };
  return (
    <>
      <div className="d-flex justify-content-end gap-10 flex-wrap">
        <Search callApi={filterListMediums} list={filterList} searchValue={searchValue} setSearchValues={setSearchValues} setFilterValues={setFilterValues}
          filterValues={filterValues} isDatepickerShow={1} />
        <Filter
          name={"Bulk Actions"}
          list={bulkList}
          changeHandler={changeHandler}
        />
        {/* <Button
          status={0}
          type="button"
          onClick={filterReset}
          className={`resend-btn  width-80px  reset-btn-123`}
          title="Reset"
        /> */}
      </div>
      <CsvDownload
        href={
          `${process.env.REACT_APP_API_URL_EXPORT}` +
          "export-medium-list?user_type=professional"
        }
      // usertype={"professional"}
      // data={fireFilter(professionals)}
      // headers={headers}
      // filename={"professionals"}
      />
      {/* <div className="filter-box-row">
      <div className="left-filtr">
        <em>Show Entries</em>
        <select id="sel" name="sel">
          <option>1</option>
          <option>2</option>
          <option>3</option>
          <option>4</option>
          <option>5</option>
          <option>6</option>
          <option>7</option>
          <option>8</option>
          <option>9</option>
          <option>10</option>
        </select>
      </div>
      <div className="right-filtr">
        <div className="add-neapp">
          <a href="#">
            <img src="../images/fill-plus.png" />
            Add New Medium
          </a>
        </div>
        <div className="search-box">
          <input
            className="search-input"
            type="text"
            placeholder="Search something.."
          />
          <button className="search-btn">
            <img src="../images/search.png" />
          </button>
        </div>
        <div className="filter-box">
          <div className="btn-group dropdown-filter">
            <button
              type="button"
              className="btn btn-default dropdown-toggle"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <img src="../images/filter.png" className="filtr-img" />
              Filter by
              <img src="../images/dropdown.png" className="drop-filtr" />
            </button>
          </div>
        </div>
        <div className="menu-dot">
          <a href="#">
            <img src="../images/dote.png" />
          </a>
        </div>
      </div>
    </div> */}
      <div className="table-box-medium">
        <table className="caseTable">
          <tbody>
            <tr className="dark">
              <th>
                <input
                  onChange={(e) => checkBoxChangeHandler(e)}
                  type="checkbox"
                  id="one"
                />
                <label htmlFor="one"></label>
                &nbsp;
              </th>
              <th>
                {" "}
                <div className="d-flex align-items-center">
                  {" "}
                  <span>Screen Name</span>{" "}
                  <Sorting callApi={filterListMediums} />
                </div>
              </th>

              <th>Email</th>
              <th>Test User</th>
              <th>Starter Referral Code</th>
              <th>Total Starter Referrals</th>
              <th>Approved Role</th>
              <th>Online status</th>
              <th>Enable/Disable</th>
              <th className="text-right">Actions</th>
              {/* <th>Action</th> */}
            </tr>
            {professionals.length > 0 ? (
              professionals.map((professional) => (
                <tr
                  onClick={(e) => navigateEdit(e, professional)}
                  className="innr pointer"
                >
                  <td >
                    <div className="d-flex-medium-tables">
                      <Checkbox
                        medium={professional}
                        status={checked}
                        selected={selected}
                        setSelected={setSelected}
                      />
                      <img
                        src="/images/undraw_profile.svg"
                        className="profile-image-avatar"
                        alt="avatar"
                      />
                    </div>
                  </td>
                  <td className="capitalize">
                    {" "}
                    {professional.screen_name
                      ? professional.screen_name
                      : "N/A"}{(professional.comments != null && professional.comments !== "") && (<b  style={{
                        fontSize: '22px',
                      }}>*</b>)}
                    {/* {professional.first_name} &nbsp; {professional.last_name} */}
                  </td>
                  <td>
                    {professional.email}
                    <CopyComponent value={professional.email} />
                  </td>
                  <td><ToggleTestUser
                    clientId={professional.id}
                    initialStatus={professional.test_user === 1} // Set initial toggle state
                    onStatusChange={(id, newStatus) => {
                      console.log(`Client ${id} status changed to ${newStatus ? 'on' : 'off'}`);
                      // Additional handling if necessary
                    }}/></td>
                  <td>{professional.refer_code ? professional.refer_code : "-"}</td>
                  <td>{professional.number_of_uses}</td>
                  <td className="change">
                    <em>
                      {getMediumRole(professional.current_mediumship_level)}
                    </em>
                    {/* <a href="#">Change</a> */}
                  </td>
                  <td>
                    <UserStatus status={professional.is_available} />
                  </td>
                  <td>
                    <div className="min-width-70px ">
                      <Toggle item={professional} />
                    </div>
                  </td>
                  <td className="dott">
                    <img
                      className="padding-10"
                      onClick={(e) =>
                        onClickHandler(e, professional.id, "professional")
                      }
                      src="images/Unionblck.png"
                      alt="dots"
                    />
                    {professional.status && (
                      <div className="approve-btn-1 approve-btn z-index-1">
                        <span
                          onClick={(e) => {
                            e.stopPropagation();
                            window.renderFirst = false;
                            navigate(
                              `/services?schedule=schedule&id=${professional.id}&view=3&level=${professional.current_mediumship_level}&search=${filterValues}&searchName=${Object.values(searchValue)}&page=${info.page}&rowperpage=${info.rowsPerPage}`
                            );
                          }}
                        >
                          Schedule
                        </span>
                        <span
                          onClick={(e) => {
                            e.stopPropagation();
                            window.renderFirst = false;
                            navigate(
                              `/availability/${professional.id}?&view=3&search=${filterValues}&searchName=${Object.values(searchValue)}&page=${info.page}&rowperpage=${info.rowsPerPage}`
                            );
                          }}
                        >
                          Calendar
                        </span>
                        <span onClick={(e) => navigateUser(e, professional)}>
                          View
                        </span>
                        <span
                          onClick={(e) => {
                            publicViewHandler(e, professional);
                          }}
                        >
                          Public View
                        </span>
                        <span
                          onClick={(e) => {
                            e.stopPropagation();
                            updateUser(
                              professional,
                              parseInt(professional.status_name) === 4 ? 1 : 4,
                              "professional",
                              ""
                            );
                          }}
                        >
                          {parseInt(professional.status_name) === 4
                            ? "UnRestrict"
                            : "Restrict"}
                        </span>

                        <span
                          onClick={(e) => {
                            e.stopPropagation();
                            updateUser(professional, 3, "delete", "");
                          }}
                        >
                          Delete
                        </span>
                        <span
                          onClick={(e) => {
                            deleteFeedBack(e, professional);
                          }}
                        >
                          Delete Feedbacks
                        </span>

                      </div>
                    )}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={6} className="text-center">
                  No Mediums Found
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <Pagination
          rowsPerPageOptions={[5, 10, 25]}
          count={allProfessionalsCount}
          getPaginationData={getPaginationData}
          activeTab={"professionals"}
          professionalPage={professionalPage}
          professionalrowsPerPage={professionalrowsPerPage}
        />
      </div>
    </>
  );
}
