
import React, { useEffect, useState } from "react";
// import { items, slotsArray } from "../../calendar/data";
import moment from "moment";
import Select from "react-select";
import ReactSelect from "react-select";
import { reschedueAppointment, getAppointmentsDetails } from "../../../../services/admin";
import { da } from "date-fns/locale";
import { toast } from "react-toastify";
import { getTimeImage } from "../../../../utils/utils";
import PreviewModal from "../../calendar/previewModal";
import Button from "../../../../components/button";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setBookingDate, setBookingTime } from "../../../../reducer/clientsSlice";
export default function ReScheduleAppt({
    // usersListMedium,
    // usersListClient,
    reschedeId,
    reschedeData,
    getAllAppointmentsList,
    activeTab,
    setReschedeId,
    setReschedeData,
    selectedIndex,
    setSelectedIndex
}) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {
        bookingdate,
        bookingtime,
        bookingIds,
    } = useSelector((state) => state.client);
    const [time, setTime] = useState("");
    const [date, setDate] = useState("");
    const [medium_timezone, setMediumTimezone] = useState("");
    const [client_timezone, setClientTimeZone] = useState("");
    const [mediumdate, setMediumDate] = useState("");
    const [mediumtime, setMediumTime] = useState("");
    const [timeslots, setTimeslots] = useState([]);
    // const [allowButton, setAllowButton] = useState(false);


    const [errorType, setErrorType] = useState([]);
    const [colorType, setColorType] = useState("red");



    const [warningMessage, setWarningMessage] = useState('');
    const [preferncesSection, setpreferncesSection] = useState(null);
    const [preview, setPreview] = useState(false);
    const [activesession_id, setActivesession_id] = useState("");
    const [activeSubSessionId, setActiveSubSessionId] = useState('');
    const [is_group_session, setis_group_session] = useState(null);


    useEffect(() => {
        if (Object.values(reschedeId).length > 0) {



            setTime("");
            setDate(reschedeData.timezone.client_timezone_date);
            setTimeslots(reschedeData.slots);
            setMediumTimezone(reschedeData.timezone.medium_timezone);
            setClientTimeZone(reschedeData.timezone.client_timezone);


        }
        // if(!reschedeData){
        //     setColorType("black");
        //     setErrorType(["No Slots Found"]);
        // }


    }, [reschedeId])

    const onInputChange = (e) => {
        setErrorType([]);
        setWarningMessage('');
        let name = e.target.name;
        let value = e.target.value;
        if (name === "date") {
            setTime("");
            setDate(value);
            let body = {
                appointment_id: reschedeId.id,
                date: value
            }
            getAppointmentsDetails(body).then((data) => {
                // settimeslots(data.data.data);

                let info = [];
                if (data.data.status) {

                    if (data.data.data.slots.length > 0) {

                        setTimeslots(data.data.data.slots);
                    } else {

                        info.push(data.data.message);
                        setMediumDate('');
                        setMediumTime('');
                        setTime("");
                        setTimeslots([]);
                        setColorType("black");
                        setErrorType(info);
                    }
                }

                // setReschedeId(item)
                // setSelectedIndex(index)
                // window.loadModal2("#ReScheduleAppt", "show");
            }).catch(() => { })
        }
        if (name === "time") {

            setTime(value);
            if (value !== "") {
                let body = {
                    appointment_id: reschedeId.id,
                    date: date,
                    time: value
                }
                getAppointmentsDetails(body).then((data) => {
                    // settimeslots(data.data.data);

                    let info = [];
                    if (data.data.status) {

                        if (data.data.data.slots) {
                            setMediumDate(data.data.data.timezone.medium_timezone_date)
                            setMediumTime(data.data.data.timezone.medium_timezone_time)
                            // setMediumDate(data.data.data.date);
                        }
                    }
                    // else{
                    //     console.log("mesg",data.data.message);
                    //     info.push(data.data.message);
                    //     setTime("");
                    //     setTimeslots("");
                    //     setColorType("black");
                    //    setErrorType(info);
                    // }
                    // setReschedeId(item)
                    // setSelectedIndex(index)
                    // window.loadModal2("#ReScheduleAppt", "show");
                }).catch(() => { })
            }
            // reSchedule(value, false);
        }

    };

    const reSchedule = (value, check) => {
        let body = {
            date: bookingdate,
            time: bookingtime,
            appointment_id: reschedeId.id

        };

        reschedueAppointment(body)
            .then((data) => {

                if (data.data.status) {
                    let info = [];
                    // if (data.data.message === "Slot Booked Successfully") {
                        dispatch(setBookingTime(""));
                        dispatch(setBookingDate(""));
                        setTime("");
                        setDate("");
                        // setColorType("red");
                        setErrorType([]);
                        // setAllowButton(false);
                        setWarningMessage('')
                        setMediumDate('');
                        setMediumTime('');
                        window.loadModal2("#ReScheduleAppt", "hide");
                        window.removeBackdrop()
                    // }

                    info.push(data.data.message)
                    toast.success(data.data.message, { position: "top-center" });
                    const dat = {
                        page: 1,
                        rowsPerPage: 10,
                    };

                    getAllAppointmentsList(dat, activeTab);

                  
                    // setErrorType(info)
                }

                else {
                    let info = [];
                    if (data.data.error === "warning") {
                        setWarningMessage(data.data.message);
                    }


                }
            })
            .catch(() => { });
    };

    const updaterequest = () => {
        // setMediumDate('');
        setErrorType([]);
        setWarningMessage('Please select another date/time');
    }
    const cancelRequest = () => {
        dispatch(setBookingTime(""));
        dispatch(setBookingDate(""));
        setTime("");
        setDate("");
        setTimeslots([]);
        // setAppointmentType("");

        setColorType("");
        setErrorType([]);
        // setAllowButton(false);

        setWarningMessage('')
        setMediumDate('');
        setMediumTime('');
        // setClientTimer('')
        setReschedeId('')


        window.loadModal2('#ReScheduleAppt', 'hide');
    }

    // const checkClientMediumTime = (name, mediumID, clientId) => {
    //     let body;
    //     if (name === "client") {
    //         body = {
    //             client_id: clientId,
    //             medium_id: mediumID
    //         }
    //     }
    //     else {
    //         body = {
    //             client_id: clientId,
    //             medium_id: mediumID
    //         }
    //     }
    //     console.log(body, "body")
    //     getClientMediumTimeZone(body).then((data) => {
    //         // if(data.data.status){
    //         setMediumTimeZone(data.data.data.medium_timezone);
    //         setClientTimeZone(data.data.data.client_timezone)
    //         // }
    //     }).catch(() => {

    //     })
    // }
    const openPreviewModal = (item) => {
        addQueryParams(item.medium_id)
        setActiveSubSessionId(item.subsession_table_id);
        setis_group_session(item.is_group_session);
        setActivesession_id(item.session_type);
        setPreview(true);
        localStorage.removeItem('showpreview');
        localStorage.removeItem('showpreviewValue');
        window.loadModal2("#prvvvvv", "show");

    };
    const addQueryParams = (id) => {
        const params = new URLSearchParams();
        params.append('id', id);



        navigate({
            pathname: '/appointments',
            search: params.toString(),
        });
    };

    useEffect(() => {
        console.log(bookingdate,
            bookingtime,
            bookingIds,)
        if (bookingtime !== "") {


            setTime(bookingtime);
            if (bookingtime !== "") {
                let body = {
                    appointment_id: reschedeId.id,
                    date: bookingdate,
                    time: bookingtime
                }
                getAppointmentsDetails(body).then((data) => {
                    // settimeslots(data.data.data);


                    if (data.data.status) {
                        // setTimeout(() => {
                        window.loadModal2("#prvvvvv", "hide");
                        setPreview(false);
                        //     dispatch(setBookingTime(""));
                        //     dispatch(setBookingDate(""));
                        // }, 1000);

                        if (data.data.data.slots) {

                            setMediumDate(data.data.data.timezone.medium_timezone_date)
                            setMediumTime(data.data.data.timezone.medium_timezone_time)
                            // setMediumDate(data.data.data.date);
                        }
                    }
                    // else{
                    //     console.log("mesg",data.data.message);
                    //     info.push(data.data.message);
                    //     setTime("");
                    //     setTimeslots("");
                    //     setColorType("black");
                    //    setErrorType(info);
                    // }
                    // setReschedeId(item)
                    // setSelectedIndex(index)
                    // window.loadModal2("#ReScheduleAppt", "show");
                }).catch(() => { })
            }
            // reSchedule(value, false);

        }
    }, [bookingdate,
        bookingtime,
        bookingIds])
    return (
        <>
            <div className="register-medium">
                <div
                    className="modal fade "
                    id="ReScheduleAppt"
                    data-backdrop="static"
                    role="dialog"
                >
                    <div className="width-unset  modal-dialog advanceConfirmbooking modal-lg  modal-dialog-preview height-unset">
                        <div className="modal-content p-5">
                            <div className="modal-body">
                                <div className="">
                                    <div className="schedule-popup">
                                        <h3 className="mb-4 mt-2 text-center mt-2">
                                            Re-Schedule Appointment e({'2A' + reschedeId.id + 'X'})
                                        </h3>
                                        {warningMessage !== "" && <div className={`eroorbox warning-color`}>{warningMessage}</div>}
                                        {errorType.map((item) => (
                                            <div className={`eroorbox ${colorType}`}>{item}</div>
                                        ))}




                                    </div>

                                    <div>
                                        <div className="from-fild ml-2 text-left">
                                            <span className="text-center d-block mb-1 font-size-22">
                                                {" "}
                                                Medium Timezone: {" "}
                                                {medium_timezone}
                                                {/* {reschedeData.timezone.medium_timezone} */}
                                            </span>

                                            {reschedeId.client_timezone !== "" &&
                                                <div className="from-fild mt-2 mb-4 text-left font-size-22">
                                                    <span className="text-center d-block mb-1">
                                                        {" "}
                                                        Client Timezone:
                                                        {" "}

                                                        {client_timezone}
                                                        {/* {reschedeData.timezone.client_timezone} */}
                                                    </span>
                                                </div>

                                                // </div>
                                            }
                                            {/* </div> */}
                                        </div>
                                        <label className="font-size-17" htmlFor="date">
                                            Please Select date and Time According to Client Timezone:
                                        </label>
                                        <div className="d-flex align-items-center justify-content-center">
                                            <div className="from-fild mt-2 mb-4 text-left width-150px max-width-150px mr-3">
                                                <label className="font-size-17" htmlFor="firstName">
                                                    {/* Select */}
                                                    Date
                                                </label>
                                                <div className=" align-items-center justify-content-center text-underline">
                                                    {reschedeId.date}
                                                    {/* <input
                                                     
                                                        onChange={onInputChange}
                                                        name="date"
                                                        type="date"
                                                        className="d-block p-2"
                                                        value={date}
                                                        min={moment().format('YYYY-MM-DD')}
                                                    /> */}
                                                </div>
                                            </div>
                                            <div className="from-fild mt-2 mb-4 text-left width-150px max-width-150px">
                                                <label className="font-size-17" htmlFor="firstName">
                                                    {/* Select */}
                                                    Time
                                                </label>
                                                <div className=" align-items-center justify-content-center text-underline">
                                                    {reschedeId.time}
                                                    {/* <select
                                                      
                                                        onChange={onInputChange}
                                                        name="time"
                                                        className="p-2"
                                                        value={time}

                                                    >
                                                        {<option value={""} selected disabled>
                                                            {'Time'}

                                                        </option>}
                                                        {timeslots.length > 0 && timeslots.map((item) => (
                                                            <option className="moon-create-app star-imh-moon" value={item}>

                                                                {item == "11:59 PM" ? "12:00 AM" : item}
                                                                {getTimeImage(item

                                                                ) ? <span>🌙</span> : <></>}


                                                            </option>
                                                        ))}
                                                    </select> */}
                                                </div>
                                            </div>

                                        </div>
                                        <div className="d-flex align-items-center justify-content-center mr-5 mb-3">
                                            <Button
                                                status={0}
                                                type="button"
                                                className={`resend-btn w-unset height-35 min-width-130px `}
                                                title={"Click here to select date & time"}
                                                onClick={() => openPreviewModal(reschedeId)}
                                            />
                                        </div>
                                        <label className="font-size-17" htmlFor="date">

                                            Medium Date & Time
                                        </label>
                                        <span><h2>
                                            {/* {mediumdate} */}
                                            {mediumdate !== "" && mediumtime !== "" ? mediumdate + ', ' + mediumtime : ""}
                                        </h2></span>
                                    </div>
                                    {/* <div className="font-size-17"><span>{"Medium Date & Time:"}{""}{"abc"}</span></div> */}

                                    <div className="confirm-delete-wrapper ">
                                        <div className="confirm-btn-box">
                                            <div
                                                className="register-medium-confirnation"
                                                onClick={cancelRequest}
                                            // data-dismiss="modal"

                                            >
                                                Cancel
                                            </div>
                                            <div
                                                className="register-medium-confirnation"
                                                // data-dismiss="modal"
                                                onClick={() => date !== "" && time !== "" ? reSchedule(time, true) : updaterequest()

                                                }
                                            >
                                                Update
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {preview && (
                <PreviewModal
                    user_type=""
                    is_group_session={is_group_session}
                    setPreview={setPreview}
                    activesession_id={activesession_id}
                    setActivesession_id={setActivesession_id}
                    type={'admin'}
                    preferncesSection={preferncesSection}
                    activeSubSessionId={activeSubSessionId}
                />
            )}
        </>
    );
}
