import React, { useEffect, useState } from "react";
// import {
//     doAProfessionalPayment,
//     payWithPaypal,
//     payWithStripe,
// } from "../../services/client";
import { useSelector } from "react-redux";

export default function PayOptionsPopUp({
    paymentInfo,
    session_id,
    subsession_id,
    medium_id,
    price,
    duration,
    bookingTime,
    bookingDate,
    setCounterStatus,
    createBooking
}) {

    ;

    const paymentMethodHandler = (type) => {
        let item = {

            paymenttype: type,
        };
        createBooking(item)

    }

    const openWaitingContainer = () => {
        setCounterStatus(true);
        window.loadModal2("#payoptionspopupmediumbooking", "hide");
    };


    const checkPyamentType = (info, type) => {
        let status = false;

        if (type === "paypal") {

            if (info !== undefined &&
                info.is_paypal) {
                status = true;
            }
            else {
                status = false;
            }
        }


        if (type === "stripe") {
            if (info !== undefined &&
                info.is_stripe) {
                status = true;
            }
            else {
                status = false;
            }
        }


        if (type === "squareup") {

            if (info !== undefined &&
                info.is_squareup) {
                status = true;
            }
            else {
                status = false;
            }

        }
        return status;
    }
    return (
        <div
            className="modal"
            id="payoptionspopupmediumbooking"
            role="dialog"
            data-backdrop="true"
        >
            {console.log(paymentInfo, "paymentInfo")}
            <div className="modal-dialog payment-dialog modal-dialog-preview">
                <div className="modal-content margin-top-300 border-waiting-time">
                    <div className="modal-body modal-body-waiting-time">
                        <button type="button" className="close" data-dismiss="modal">
                            &times;
                        </button>
                        <div className="profile-video"></div>
                        <h3 className="text-center mt-2">Pay With</h3>
                        <div className="text-center mt-2">   {paymentInfo !== undefined && paymentInfo !== "" && <h5>You have payment of :  ${paymentInfo.coaching_pricing !== null && Object.values(JSON.parse(JSON.parse(paymentInfo.coaching_pricing)))[0]}</h5>}</div>
                        <h4 className="text-center mt-2">Select any of the following secure payment processors to finalize, and
                        book your appointment.</h4>
                        <div>
                            <div className="payment-new-wrapper mt-5">
                                
                                <div className="payment-options-container">

                                    {checkPyamentType(paymentInfo, 'paypal') && (
                                        <div className="payment-option">
                                        <button
                                            onClick={() => paymentMethodHandler("paypal")}
                                            data-toggle="modal"
                                            className={`main-btn`}
                                        >
                                            {" "}
                                            <img src={"/integrationimages/paypal.png"} />
                                        </button>
                                        <p>(PayPal or credit/debit card)</p>
                                        </div>
                                    )}
                                    {checkPyamentType(paymentInfo, 'stripe') &&
                                    <div className="payment-option">
                                        <button
                                            onClick={() => paymentMethodHandler("stripe")}
                                            data-toggle="modal"
                                            className={`main-btn`}
                                        >
                                            {" "}
                                            <img
                                                className="stripe-img"
                                                src={"/integrationimages/stripe.png"}
                                            />
                                        </button>
                                        <p>(credit/debit card)</p>
                                        </div>
                                    }
                                    {checkPyamentType(paymentInfo, 'squareup') && (
                                        <div className="payment-option">
                                        <button
                                            onClick={() => paymentMethodHandler("squareup")}
                                            data-toggle="modal"
                                            className={`main-btn font-size-22`}
                                        >
                                            {" "}
                                            Square
                                            {/* <img
                      className="stripe-img"
                      src={"/integrationimages/stripe.png"}
                    /> */}
                                        </button>
                                        <p>(credit/debit card)</p>
                                    </div>
                                    )}
                                </div>
                            </div>
                            {/* <PaymentPrices paymentMethodHandler={paymentMethodHandler} setPaymentAmount={setPaymentAmount} /> */}
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}
