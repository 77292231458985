import React, { useEffect, useState } from "react";
import MultiDatePicker from "react-multi-date-picker";
import moment from "moment";
import "./goondemand.css";
import { useDispatch, useSelector } from "react-redux";
import Questions from "./questionsbox";
import ScheduledetailsModal from "../../admin/ondemandupdates/scheduledetails";
import {
  acceptOnDemandSession,
  addMediumToOnDemandQueue,
  bookOnDemandSession,
  checkMediumInOnDemandQueue,
  clearOnDemandCache,
  createOnDemandBooking,
  getMediumOndemandEvents,
  getZoomLinkOnDemand,
  rejectOnDemandSession,
  removeMediumFromOnDemandQueue,
  removePairFromCache,
} from "../../../services/medium";
import AlredyJoined from "./alreadyJoined";
import ConfirmOut from "./confirmOut";
import { el } from "date-fns/locale";
import Pusher from "pusher-js";
import ReminderPopUp from "./reminderPopup";
import { useNavigate } from "react-router-dom";
import CountdownTimerReact from "./countdown";
import { getTimeImage } from "../../../utils/utils";
import { setLoadingContent, setLoadingText } from "../../../reducer/auth/auth";
import TimeEndedPopup from "./timeendedpopup";
import TimerForEvent from "./timerforevent";
import ChooseMedium from "./choosemedium";
import { toast } from "react-toastify";
import PartnerEndedPopup from "./partnerendedpopup";

export default function GoOnDemandPractice() {
  const { timezone, id } = useSelector((state) => state.user.user);
  const dispatch = useDispatch();

  const [loaderType, setloaderType] = useState("1");
  const [inputValue, setInputValue] = useState("");
  const [info, setInfo] = useState();
  const [Index, setIndex] = useState(0);
  const [eventsList, setEventList] = useState([]);
  const [visibleCount, setVisibleCount] = useState(5);
  const [values, setValues] = useState([]);
  const [fromTime, setFromTime] = useState("");
  const [toTime, setToTime] = useState("");
  const [timer, setTimer] = useState(null);
  const [activeEvent, setActiveEvent] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [time, setTime] = useState(0);

  const [ondemandStatus, setOndamandStatus] = useState(false);
  const [counterStatus, setCounterStatus] = useState(false);
  const [shoHostStatus, setshoHostStatus] = useState(false);
  const [meeting_link, setmettingLink] = useState("");
  const [meetingLinkStatus, setmettingLinkStatus] = useState(false);
  const [appoinmentId, setAppoinmentId] = useState("");

  //new
  const [pairedUsers, setPairedUsers] = useState([]);
  const [pairedevent_id, setpairedevent_id] = useState("");
  const [runagain, setRunAgain] = useState(false);
  const [hangon, setHangOn] = useState(false);
  const [acceptSession, seAcceptSession] = useState(false);
  const [otherInfo, setOtherInfo] = useState();
  const [seconds, setSeconds] = useState(30);
  const [isActivePaired, setIsActivePaired] = useState(false);
  const [checkYes, setCheckYes] = useState(false);
  const [matchName, setMatchName] = useState("");
  const [message, setMessage] = useState("");
  const [timeLeft, setTimeLeft] = useState(""); // 10 minutes in seconds (600 seconds)
  const [isActive, setIsActive] = useState(false);
  //new
  const loadMoreItems = () => {
    setVisibleCount((prevCount) => prevCount + 5); // Load next 8 items
  };

  let interval;
  const navigate = useNavigate();

  const day = (day) => moment(day).format("ddd"); // Short day name (e.g., 'Fri')
  const month = (month) => moment(month).format("MMM"); // Short month name (e.g., 'Sep')
  const date = (date) => moment(date).format("DD");
  const times = (time) => moment(time, "HH:mm").format("hh:mm A");

  //functions
  const getEventList = (from, to, keyword) => {
    setVisibleCount(5);
    getMediumOndemandEvents(from, to, keyword)
      .then((data) => {
        const events = Object.values(data.data.data).flat();

        let newEvents = events.map((info) => {
          return {
            id: info.id,
            description: info.description,
            ondemand_session_category_id: info.ondemand_session_category_id,
            name: info.title,
            // time: times(info.start_time),
            time: info.start_time,

            monthname: month(info.date),
            date: date(info.date),
            day: day(info.date),
            subtext: info.sub_text,
            backupdate: info.date,
            end_time: info.end_time,
            showReminder: checkTime(info.date, info.start_time, info.end_time),
          };
        });

        setEventList(newEvents);
        runIntervalFunc(newEvents);
      })
      .catch(() => { });
  };

  const checkOldTime = (datee, timee) => {
    let status = false;
    const targetDate = moment(`${datee} ${timee}`, "YYYY-MM-DD hh:mm A");
    const currentDate = moment(
      moment().format("YYYY-MM-DD hh:mm A"),
      "YYYY-MM-DD hh:mm A"
    );

    // Check if the current time is past the target time
    if (currentDate.isSame(targetDate)) {
      status = true;
    } else {
      status = false;
    }
    return status;
  };
  const checkEndTime = (item) => {
    let status = true;
    let currenTime = moment().format("YYYY-MM-DD hh:mm A");

    let eventtime = moment(
      `${item.backupdate} ${item.end_time}`,
      "YYYY-MM-DD hh:mm A"
    ).format("YYYY-MM-DD hh:mm A");

    // let beforeTime = moment(eventtime).subtract(15, 'minutes');
    const differenceInMinutes = moment(eventtime).diff(currenTime, "seconds");
    return differenceInMinutes;

    // return differenceInMinutes;
  };
  const expireSession = (item) => {
    // checkEndTime(item);
    if (checkEndTime(item) == 0) {
      clearOnDemandCache(item.id).then(() => { });
    }
    // clearOnDemandCache()
  };
  const runIntervalFunc = (event = []) => {
    interval = setInterval(() => {
      let list = eventsList.length > 0 ? eventsList : event;
      let newlist = list.filter((item) => {
        expireSession(item);
        if (!checkOldTime(item.backupdate, item.end_time)) {
          return item;
        } else {
          return;
        }
      });
      let newEvent = newlist.map((item) => {
        // console.log(item.backupdate, item.time);
        let status = checkTime(item.backupdate, item.time, item.end_time);
        return {
          ...item,
          showReminder: status,
        }; // Example: Incrementing each value
      });
      // console.log(newEvent, "newEvent")
      setEventList(newEvent);
    }, 6000); // 60 seconds = 60000 ms
    // Cleanup the interval on component unmount
    return () => clearInterval(interval);
  };
  const getClientProfileDetails = (item, index) => {
    setIndex(index);
    setInfo(item);
    window.loadModal2("#ScheduledetailsModal", "show");
  };
  const handleValueChange = (newValues) => {
    if (newValues) {
      setValues(newValues);
      let name = {};
      if (newValues.length === 2) {
        const startDate = newValues[0].format("YYYY-MM-DD");
        const endDate = newValues[1].format("YYYY-MM-DD");
        // name["created_at"] = `${startDate},${endDate}`; // Fix the assignment
        setFromTime(startDate);
        setToTime(endDate);
        getEventList(startDate, endDate, "");
      }
      // if(newValues.length === 1){
      //   console.log( newValues[0].format("YYYY-MM-DD"))
      //   const startDate = newValues[0].format("YYYY-MM-DD");
      //   getEventList('', '','',startDate)
      // }
    }
  };
  const inputChanged = (e) => {
    let value = e.target.value;
    setInputValue(e.target.value);

    clearTimeout(timer);

    const newTimer = setTimeout(() => {
      if (e.target.value.length > 2 && e.target.value.length === 0) {
        getEventList(fromTime, toTime, value);
      }
    }, 2000);

    setTimer(newTimer);
  };
  const checkTime = (date, time, end) => {
    const dateToCheck = moment(date); // Replace with the date you want to check
    const isToday = dateToCheck.isSame(moment(), "day");
    // console.log(isToday, date)

    if (isToday) {
      // Convert input to moment
      let currentTime = moment().format("YYYY-MM-DD HH:mm");
      let endtime = moment(`${date} ${end}`, "YYYY-MM-DD hh:mm A").format(
        "YYYY-MM-DD HH:mm"
      );

      const inputMoment = moment(
        `${date} ${time}`,
        "YYYY-MM-DD hh:mm A"
      ).format("YYYY-MM-DD HH:mm");
      const time1 = moment(currentTime, "YYYY-MM-DD HH:mm");
      const time2 = moment(inputMoment, "YYYY-MM-DD HH:mm");

      const time3 = moment(endtime, "YYYY-MM-DD HH:mm");
      const minutes = time3.diff(time1, "minutes");
      const minutesDifference = time1.diff(time2, "minutes");
      let status = minutesDifference >= 0 && minutes > 0;

      return status;
    } else {
      return isToday;
    }
    return;

    // Check if the difference is between 0 and 10 (inclusive) or exactly 0
  };
  const openReminderPopup = (info) => {
    localStorage.setItem("activeEvent", info.id);
    setActiveEvent(info);
    window.loadModal2("#ReminderPopUp", "show");
  };
  const checkMediumInOnDemand = (info, check, user_pereference) => {
    dispatch(setLoadingContent(`<div>Please Wait ...</div>`));
    dispatch(setLoadingText(true));
    setTime(0);

    setOndamandStatus(false);
    checkMediumInOnDemandQueue()
      .then((data) => {
        dispatch(setLoadingContent(""));
        dispatch(setLoadingText(false));
        setActiveEvent(info);
        if (!data.data.data) {
          if (check == "other") {
            addMediumQueue(user_pereference);
          } else {
            localStorage.setItem("activeEvent", info.id);
            localStorage.setItem("ondemandLoader", "2");
            localStorage.removeItem("matched");
            setloaderType("2");
          }
        } else {
          window.loadModal2("#AlredyJoined", "show");
        }
      })
      .catch(() => { });
  };

  const addMediumQueue = (check) => {
    setRunAgain(false);
    // dispatch(setLoadingContent(`<div>Please Wait ...</div>`));
    // dispatch(setLoadingText(true));
    console.log(activeEvent, "activeEvent");
    let event_id = activeEvent.id;
    let date = activeEvent.backupdate;
    let end_time = activeEvent.end_time;
    let user_pereference = check == "yes" ? 0 : 1;
    localStorage.setItem("ondemandLoader", "3");
    setloaderType("3");
    // setTimeout(() => {


    addMediumToOnDemandQueue(event_id, date, end_time, user_pereference)
      .then((data) => {
        dispatch(setLoadingContent(""));
        dispatch(setLoadingText(false));
        setHangOn(false);
        setIsActivePaired(false);
        setSeconds(30);
        if (data.data.data) {
          let matched = localStorage.getItem("matched");
          if (matched) {
            return;
          }


          setIsActive(true);
          checkTimes();
        }
      })
      .catch(() => { });
    // }, 5000);
  };

  const removeMediumFromQueue = () => {
    let ids = JSON.parse(localStorage.getItem("activeEvent"));
    if (ids) {
      removeMediumFromOnDemandQueue(ids)
        .then((data) => {
          console.log(data)

          if (data.data.data) {
            setTime(0);
            setOndamandStatus(true);
            setTimeout(() => {
              setloaderType('1');
              dispatch(setLoadingContent(""));
              dispatch(setLoadingText(false));
            }, 1000);



          }
          else {
            dispatch(setLoadingContent(""));
            dispatch(setLoadingText(false));
            toast.error("There is problem while Removing you", { position: "top-center" })
          }
        })
        .catch(() => {
          dispatch(setLoadingContent(""));
          dispatch(setLoadingText(false));
        });
    }
  };
  const startLoaderNow = (user_pereference) => {
    checkMediumInOnDemand(activeEvent, "other", user_pereference);
    setTime(0);
  };

  const getZoomLink = (id) => {
    let status = true;
    getZoomLinkOnDemand(id)
      .then((data) => {
        // if (status) {
        if (!data.data.data) {
          setTimeout(() => {
            getZoomLink(appoinmentId);
          }, 5000);
        } else if (data.data.success == "Appointment Zoom Link") {
          setmettingLink(data.data.data);
          setTimeout(() => {
            navigate("/appointments?from=goondemand");
          }, 300000);
        }
      })
      .catch((er) => {
        console.log(er);
      });
  };
  const hideCountdownTimer = () => {
    setCounterStatus(false);
  };

  const fifteenMinutesBefore = (item) => {
    let status = true;
    let currenTime = moment().format("YYYY-MM-DD hh:mm A");

    let eventtime = moment(
      `${item.backupdate} ${item.time}`,
      "YYYY-MM-DD hh:mm A"
    ).format("YYYY-MM-DD hh:mm A");

    let beforeTime = moment(eventtime).subtract(15, "minutes");
    const differenceInMinutes = moment(eventtime).diff(currenTime, "minutes");
    if (differenceInMinutes > 0 && differenceInMinutes < 16) {
      status = false;
    } else {
      status = true;
    }
    return status;
  };
  const backHandler = () => {
    dispatch(
      setLoadingContent(
        `<div>Please wait while we are removing you from queue ...</div>`
      )
    );
    dispatch(setLoadingText(true));

    let matched = localStorage.getItem("matched");
    if (!matched) {
      removeMediumFromQueue();
    }

  };



  const checkTimes = () => {
    let status = true;
    let currenTime = moment().format("YYYY-MM-DD hh:mm A");

    let eventtime = moment(
      `${activeEvent.backupdate} ${activeEvent.end_time}`,
      "YYYY-MM-DD hh:mm A"
    ).format("YYYY-MM-DD hh:mm A");

    // let beforeTime = moment(eventtime).subtract(15, 'minutes');
    const differenceInMinutes = moment(eventtime).diff(currenTime, "seconds");
    console.log(differenceInMinutes, "differenceInMinutes", "seconds");
    setTimeLeft(differenceInMinutes);
    setIsActive(true);
    // return differenceInMinutes;
  };
  const checkTimesEnd = () => {
    let status = true;
    let currenTime = moment().format("YYYY-MM-DD hh:mm A");

    let eventtime = moment(
      `${activeEvent.backupdate} ${activeEvent.end_time}`,
      "YYYY-MM-DD hh:mm A"
    ).format("YYYY-MM-DD hh:mm A");

    // let beforeTime = moment(eventtime).subtract(15, 'minutes');
    const differenceInMinutes = moment(eventtime).diff(currenTime, "seconds");
    return differenceInMinutes;

    // return differenceInMinutes;
  };
  const openTimer = (item) => {
    setActiveEvent(item);
    window.loadModal2("#TimerForEvent", "show");
  };

  const rejectPaired = () => {
    let body = {
      pair: pairedUsers,
      event_id: pairedevent_id,
    };
    rejectOnDemandSession(body)
      .then((data) => {
        if (data.data.data) {
          // toast.error("Sorry that partner is no longer available", { position: "top-center" })
          setRunAgain(true);
          setHangOn(false);
          setIsActivePaired(false);
          setSeconds(30);
        }
        console.log(data, "data");
      })
      .catch(() => { });
  };

  const acceptPaired = () => {
    setHangOn(true);
    let body = {
      pair: pairedUsers,
      event_id: pairedevent_id,
    };
    // if (acceptSession) {
    //   createOnDemandBooking(body)
    //     .then((data) => {
    //       console.log(data);
    //       if (data.data.data) {
    //         console.log(data);
    //       }
    //     })
    //     .catch(() => { });
    // } else {
    acceptOnDemandSession(body)
      .then((data) => {
        console.log(data);

        if (data.data.data) {
          console.log(data);
        }
        else {
          setHangOn(false);
        }
      })
      .catch(() => { });
    // }
  };

  const addMediumToQueueAgain = (no) => {
    if (no == "no") {
      localStorage.removeItem("again_started");
    } else {
      localStorage.setItem("again_started", "yes");
    }
    let body = {
      pair: pairedUsers,
    };
    removePairFromCache(body).then((data) => {
      if (data.data.data) {
        addMediumQueue(checkYes);
      }
    })

    setTimeout(() => {
      window.loadModal2("#PartnerEndedPopup", "hide");
    }, 1000);
  };
  const handleStart = () => setIsActivePaired(true);
  const handleStop = () => setIsActivePaired(false);

  //effects
  useEffect(() => {
    localStorage.removeItem("matched");
    localStorage.setItem("ondemandLoader", "1");
    const from = moment().format("YYYY-MM-DD");
    const to = moment()
      .add(30, "days")
      .format("YYYY-MM-DD");
    setFromTime(from);
    setToTime(to);

    getEventList(from, to, "");
  }, []);

  useEffect(() => {
    if (time > 0 && !ondemandStatus) {
      let status = checkOldTime(activeEvent.backupdate, activeEvent.end_time);
      if (status) {
        setOndamandStatus(true);
        removeMediumFromQueue();
        localStorage.setItem("ondemandLoader", "1");
        setloaderType("1");
        return;
      }
    }
  }, [time, ondemandStatus]);

  useEffect(() => {
    const handleBeforeUnload = (event, confirmExit) => {
      console.log(event, confirmExit)
      let ondemandLoader = localStorage.getItem("ondemandLoader");
      if (ondemandLoader == "3") {
        event.preventDefault();
        // setIsActive(false);
        // removeMediumFromQueue();
        // event.returnValue = "";
      } else {
        event.preventDefault();
      }
    };
    const handleUnload = (event) => {
      console.log(event);
      // event.preventDefault();
      setIsActive(false);
      removeMediumFromQueue();
      event.returnValue = "";
      console.log("User chose to leave the page");
      // You can run any final cleanup code here before the page unloads
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    window.addEventListener("unload", handleUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      window.removeEventListener("unload", handleUnload);
    };
  }, []);

  useEffect(() => {
    Pusher.logToConsole = false;
    var pusher = new Pusher("823ab72ed6ecd563b8c8", {
      cluster: "ap2",
    });

    var channel = pusher.subscribe("ondemand-booking");
    channel.bind("App\\Events\\OnDemandBooking", function (data) {
      console.log(data, "data");
      if (data.appointment !== undefined) {
        if (
          data.appointment.medium_id == id ||
          data.appointment.user_id == id
        ) {
          // console.log(data, "data");
          setTime(0);
          setCounterStatus(true);
          setOndamandStatus(true);
          setMessage(data.message);
          setAppoinmentId(data.appointment.id);
          localStorage.setItem("appoinmentId", data.appointment.id);
          setmettingLink(data.appointment.meeting_link);
          setIsActivePaired(false);
          localStorage.setItem("matched", "matched");
          if (
            data.appointment.meeting_link == null ||
            data.appointment.meeting_link == ""
          ) {
            getZoomLink(data.appointment.id);
          }
          console.log(data.appointment.meeting_link, "data.meeting_link");

          setloaderType("4");

          if (data.appointment.medium_id == id) {
            setshoHostStatus(true);
          } else {
            setshoHostStatus(false);
          }
        }
      }
    });
  }, []);

  useEffect(() => {
    return () => {
      localStorage.removeItem("matched");
      removeMediumFromQueue(); // Call the API when the component unmounts
    };
  }, []);

  useEffect(() => {
    if (activeEvent !== undefined) {
      // window.loadModal2("#TimeEndedPopup", "show");
      if (checkTimesEnd() === 0 && isActive) {
        removeMediumFromQueue();
        setloaderType("1");
        clearOnDemandCache(activeEvent.id).then(() => { });
        setIsActive(false);
        window.loadModal2("#TimeEndedPopup", "show");
        return;
      }
      // If the time reaches zero, stop the timer.

      let intervalId;

      if (isActive && timeLeft > 0) {
        intervalId = setInterval(() => {
          // console.log(timeLeft, "setIsActive");
          setTimeLeft((prevTime) => prevTime - 1);
        }, 1000);
      }
      return () => clearInterval(intervalId); // Clean up the interval on component unmount or if the timer stops
    }
  }, [isActive, timeLeft]);

  useEffect(() => {
    if (loaderType != "3") {
      setTimeLeft("");
      setIsActive(false);
    }
    if (loaderType == "1" || loaderType == "4") {
      localStorage.removeItem("activeEvent");
    }
  }, [loaderType]);

  useEffect(() => {
    if (runagain) {
      addMediumQueue(checkYes);
    }
  }, [runagain]);

  useEffect(() => {
    if (otherInfo !== undefined) {
      if (
        pairedUsers.includes(otherInfo.other_medium_id) &&
        pairedUsers.includes(otherInfo.user_id) &&
        parseInt(otherInfo.event_id) == parseInt(pairedevent_id)
      ) {
        seAcceptSession(true);
      }
    }
  }, [otherInfo]);

  useEffect(() => {
    let timerId;
    if (isActivePaired && seconds > 0) {
      timerId = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds - 1);
      }, 1000);
    }
    if (isActivePaired && seconds == 0) {
      seAcceptSession(false);
      window.loadModal2("#PartnerEndedPopup", "show");
      // toast.error("Sorry that partner is no longer available", { position: "top-center" })
      // addMediumQueue(checkYes);
      setHangOn(false);
      setIsActivePaired(false);
      setSeconds(30);
      setTimeout(() => {
        if (localStorage.getItem("again_started") == "yes") {
          localStorage.removeItem("again_started");
        } else {
          addMediumToQueueAgain("no");
        }
      }, 5000);
    }

    // Cleanup the interval on component unmount or when the timer stops
    return () => clearInterval(timerId);
  }, [isActivePaired, seconds]);

  //webhooks effects
  useEffect(() => {

    if (loaderType == "3" || loaderType == "5") {
      Pusher.logToConsole = false;
      var pusher = new Pusher("823ab72ed6ecd563b8c8", {
        cluster: "ap2",
      });
      const randomNumber = Math.floor(Math.random() * 10) + 1;
      console.log(parseInt(`${randomNumber}000`), "randomNumber")
      var channel = pusher.subscribe("on-demand-practice-medium-confirmation");
      channel.bind("App\\Events\\OnDemandPracticeMediumConfirmation", function (
        data
      ) {
        console.log(data, "on-demand-practice-medium-confirmation");
        if (data.pair.includes(id)) {

          setPairedUsers(data.pair);
          setpairedevent_id(data.event_id);
          localStorage.setItem("pairedUsers", JSON.stringify(data.pair));
          localStorage.setItem("pairedevent_id", data.event_id);
          localStorage.setItem("ondemandLoader", "5");
          let match = data.users.filter((info) => info.id !== id);
          setMatchName(match[0].screen_name);
          // setTimeout(() => {

          setloaderType("5");
          setIsActivePaired(true);
          // }, parseInt(`${randomNumber}000`));
        }
      });
    } else {
      var pusher = new Pusher("823ab72ed6ecd563b8c8", {
        cluster: "ap2",
      });
      pusher.unsubscribe("on-demand-practice-medium-confirmation");
    }
  }, [loaderType]);

  useEffect(() => {
    if (loaderType == "5") {
      Pusher.logToConsole = false;
      var pusher = new Pusher("823ab72ed6ecd563b8c8", {
        cluster: "ap2",
      });

      var channel = pusher.subscribe("reject-on-demand-practice-pairing");
      channel.bind("App\\Events\\RejectOnDemandPracticePairing", function (
        data
      ) {
        console.log(data, "RejectOnDemandPracticePairing");
        let pairedUsers = localStorage.getItem("pairedUsers");
        let pairedevent_id = localStorage.getItem("pairedevent_id");
        console.log(
          pairedUsers,
          "JSON.stringify(pairedUsers) === JSON.stringify(data.pair)"
        );
        if (
          pairedUsers === JSON.stringify(data.pair) &&
          parseInt(data.event_id) == parseInt(pairedevent_id)
        ) {
          seAcceptSession(false);
          seAcceptSession(false);
          // setRunAgain(true);
          if (parseInt(data.rejected_user) !== parseInt(id)) {


            window.loadModal2("#PartnerEndedPopup", "show");
            setTimeout(() => {

              addMediumToQueueAgain("no");
            }, 5000);
          }

        }
        // if (data.pair.includes(id)) {
        //   setPairedUsers(data.pair);
        //   setpairedevent_id(data.event_id);
        //   setloaderType("5");
        // }
      });
    } else {
      var pusher = new Pusher("823ab72ed6ecd563b8c8", {
        cluster: "ap2",
      });
      pusher.unsubscribe("reject-on-demand-practice-pairing");
    }
  }, [loaderType]);

  useEffect(() => {
    if (loaderType == "5") {
      Pusher.logToConsole = false;
      var pusher = new Pusher("823ab72ed6ecd563b8c8", {
        cluster: "ap2",
      });

      var channel = pusher.subscribe("on-demand-practice-medium-confirmed");
      channel.bind("App\\Events\\OnDemandPracticeMediumConfirmed", function (
        data
      ) {
        console.log(data, "OnDemandPracticeMediumConfirmed");
        console.log(pairedUsers, pairedevent_id);
        setOtherInfo(data);
      });
    } else {
      var pusher = new Pusher("823ab72ed6ecd563b8c8", {
        cluster: "ap2",
      });
      pusher.unsubscribe("on-demand-practice-medium-confirmed");
    }
  }, [loaderType]);

  return (
    <>
      {loaderType == "3" && (
        <>
          <div onClick={backHandler} className="back-btn-appointments pointer">
            <div className="bck">
              <img src="images/black-erow.png" alt="back" />
              <p className="margin-unset"> Back</p>
            </div>
          </div>
          <h4 className="text-center mb-3 mt-3">
            {/* {console.log(activeEvent)} */}
            {activeEvent !== undefined && activeEvent.name}
          </h4>
          <h4 className="text-center  pl-5 pr-5">
            Hold on, we are searching for your match.
            <br /> Leaving this screen will stop your matching process.
          </h4>
          <div className="globe-gif-wrapper">

            <img className="w-100" src={"/localImages/AroundTheWorld.gif"} alt="globe" />
          </div>
          {/* <video className="loader-video" autoPlay loop>
            <source src={"/localImages/AroundTheWorld.mp4"} type="video/mp4" />
          </video> */}
          <div className="d-flex align-items-center justify-content-around mt-3">
            <div
              onClick={backHandler}
              className="font-size-22 width-350-px white-border pointer indexstyles__StyledButton-sc-83qv1q-0 jdbotF sc-1qeub3n-4  d-flex align-items-center justify-content-center"
            >
              Exit Queue
            </div>
          </div>
        </>
      )}
      {loaderType == "1" && (
        <div className="container-fluid ">
          <div className="dashboard ">
            <div className="row">
              <div className="col-md-12">
                <div className="middle-screen-name w-100 font-size-22 mt-2 mb-2">
                  <b>
                    Timezone: {timezone !== undefined && timezone}&nbsp; { }
                  </b>
                </div>
                <div className="ondemandpractice-wrapper">
                  <div></div>
                  <div className=" justify-content-between mb-2 mt-3 d-flex-flex-wrap-wrap">
                    <div className="search-box search-container ">
                      <input
                        value={inputValue}
                        onChange={inputChanged}
                        className="search-input"
                        // type={filterValues === "created_at" ? "date" : "text"}
                        placeholder="Search"
                      />
                    </div>
                    {/* <div className="filter-container-box ">
                      Dates
                    </div> */}
                    <div className="filter-wrapper filter-container-box">
                      <MultiDatePicker
                        style={{ height: "45px" }}
                        className="search-input "
                        numberOfMonths={2}
                        disableMonthPicker
                        disableYearPicker
                        range
                        value={values}
                        onChange={handleValueChange}
                        placeholder="Select date range"
                      // maxDate={new Date()}
                      />
                    </div>
                  </div>
                  <div>
                    <h3 className="ondemandpractice-heading mt-3">Events</h3>
                  </div>
                  <div>
                    <ul className="godemantevent-box-list">
                      {eventsList.slice(0, visibleCount).map((item, index) => (
                        <li>
                          <div className="godemantevent-box">
                            <div className="godemantevent-internal-box d-flex-flex-wrap-wrap">
                              <div className="godemantevent-date-box">
                                <div className="month-name">
                                  <span>{item.monthname}</span>
                                </div>
                                <div className="month-date mt-1">
                                  <span>{item.date}</span>
                                </div>
                              </div>
                              <div className="godemantevent-date-box width-55 pointer">
                                <div>
                                  <span className="ondemandpractice-date-box-day">
                                    <span aria-hidden="true">
                                      <span>{item.day}</span>
                                      <span class="sc-1idcr5x-1 dieHWG">
                                        <span>
                                          {item.time}{" "}
                                          {getTimeImage(item.time) ? (
                                            <img
                                              src="localImages/moon.png"
                                              className="star-imh-moon"
                                              alt="time-image"
                                            />
                                          ) : (
                                            ""
                                          )}
                                        </span>{" "}
                                        -{" "}
                                        <span>
                                          {item.end_time}{" "}
                                          {getTimeImage(item.end_time) ? (
                                            <img
                                              src="localImages/moon.png"
                                              className="star-imh-moon"
                                              alt="time-image"
                                            />
                                          ) : (
                                            ""
                                          )}{" "}
                                          (Matching period)
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </div>
                                <div>
                                  <span class="sc-fyofxi-6 ivIeWB">
                                    <span class="sc-fyofxi-5 gJmuwa">
                                      {item.name}
                                    </span>
                                    {/* <span class="sc-fyofxi-5 gJmuwa">Resch Center</span> */}
                                  </span>
                                  <span class="sc-fyofxi-7 jWLmQR">
                                    <span class="sc-fyofxi-5 gJmuwa">
                                      {item.subtext}
                                    </span>
                                  </span>
                                </div>
                                <div
                                  onClick={() =>
                                    getClientProfileDetails(item, index)
                                  }
                                  className="text-underline"
                                >
                                  ...More Information
                                </div>
                              </div>

                              <div className="godemantevent-date-box text-right">
                                <div>
                                  {/* () => { } */}
                                  {fifteenMinutesBefore(item) && (
                                    <a
                                      onClick={
                                        item.showReminder
                                          ? () => checkMediumInOnDemand(item)
                                          : () => openReminderPopup(item)
                                      }
                                      href="#"
                                      className={`width-175-px kCQEIv indexstyles__StyledButton-sc-83qv1q-0 jdbotF sc-1qeub3n-4 lmaHQg d-block ${item.showReminder ? "Green-bg-all" : ""
                                        }`}
                                    >
                                      <span class="indexstyles__FlexWrapper-sc-83qv1q-1 kCQEIv">
                                        <span class="indexstyles__Text-sc-83qv1q-2 jHTUWf">
                                          <span class="sc-1qeub3n-6 iwMkwK">
                                            <span class="sc-1qeub3n-5 eTlzst">
                                              {item.showReminder
                                                ? "Find a Partner"
                                                : "Remind Me"}
                                            </span>
                                          </span>

                                          <svg
                                            class="BaseSvg-sc-yh8lnd-0 ChevronIcon___StyledBaseSvg-sc-1y4em6t-0 ckLyyv sc-1qeub3n-2 bcqnRP"
                                            viewBox="0 0 24 24"
                                            width="1.5em"
                                            height="1.5em"
                                            aria-hidden="true"
                                            focusable="false"
                                          >
                                            <path d="M3.47 8.26 4.53 7.2 12 14.67l7.47-7.47 1.06 1.06L12 16.8z"></path>
                                          </svg>
                                        </span>
                                      </span>
                                    </a>
                                  )}
                                  {!item.showReminder && (
                                    <a
                                      onClick={() => openTimer(item)}
                                      href="#"
                                      class="width-175-px kCQEIv disabled-btn-join cursor-disabled indexstyles__StyledButton-sc-83qv1q-0 jdbotF sc-1qeub3n-4 lmaHQg d-block mt-2"
                                    >
                                      <span class="indexstyles__FlexWrapper-sc-83qv1q-1 kCQEIv">
                                        <span class="indexstyles__Text-sc-83qv1q-2 jHTUWf">
                                          <span class="sc-1qeub3n-6 iwMkwK">
                                            <span class="sc-1qeub3n-5 eTlzst">
                                              Find a Partner
                                            </span>
                                            <svg
                                              class="BaseSvg-sc-yh8lnd-0 ChevronIcon___StyledBaseSvg-sc-1y4em6t-0 ckLyyv sc-1qeub3n-2 bcqnRP"
                                              viewBox="0 0 24 24"
                                              width="1.5em"
                                              height="1.5em"
                                              aria-hidden="true"
                                              focusable="false"
                                            >
                                              <path d="M3.47 8.26 4.53 7.2 12 14.67l7.47-7.47 1.06 1.06L12 16.8z"></path>
                                            </svg>
                                          </span>
                                        </span>
                                      </span>
                                    </a>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                      ))}
                      <div className="d-flex align-items-center justify-content-center mt-5">
                        <div
                          onClick={loadMoreItems}
                          className="width-175-px pointer indexstyles__StyledButton-sc-83qv1q-0 jdbotF sc-1qeub3n-4  d-flex align-items-center justify-content-center"
                        >
                          Load More
                        </div>
                      </div>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <CountdownTimer counterStatus={counterStatus} hideCountdownTimer={hideCountdownTimer} /> */}
        </div>
      )}
      {loaderType == "2" && (
        <Questions
          setCheckYesMain={setCheckYes}
          startLoaderNow={startLoaderNow}
          onCancelQuestions={() => setloaderType("1")}
        />
      )}

      {loaderType == "4" && (
        <div className="ondemandpractice-wrapper margin-auto min-height-500px mt-5">
          <div className="">
            <div className="godemantevent-box-list">
              <p className="text-center mt-5 font-size-26">
                {" "}
                Your Appointment Is Confirmed!
              </p>

              {shoHostStatus && (
                <h3 className="text-center mt-5 line-height-32px">
                  You are the HOST.
                  <br />
                  <br />
                  {/* <b className="text-underline"> */}
                  {" "}
                  Be sure you are
                  logged into your Zoom <br />
                  {/* </b>{" "} */}
                  account to start
                  the session.
                </h3>
              )}
              {/* <p className="text-center mt-4 font-size-19">{message}</p> */}
              <p className="text-center mt-4 font-size-19">
                {"Please join within 2 minutes."}
              </p>
              {counterStatus && (
                <CountdownTimerReact
                  hideCountdownTimer={hideCountdownTimer}
                  meeting_link={meeting_link}
                  getZoomLink={getZoomLink}
                  appoinmentId={appoinmentId}
                />
              )}
              {/* <p className="text-center mt-3 font-size-19">
                You can either joining the session now, or go to your
                appointments
                <br /> page to find the zoom link to join the session.
              </p> */}
            </div>
            <br />
            {(meeting_link == "" ||
              meeting_link == null ||
              meeting_link == undefined) && (
                <div className="text-center mb-3 mt-1">
                  <b> Zoom Link is being created</b>
                </div>
              )}
            {meeting_link !== "" &&
              meeting_link !== null &&
              meeting_link !== undefined && (
                <div className="d-flex align-items-center justify-content-center flex-column">
                  {console.log(meeting_link, "meeting_link")}
                  {/* {meeting_link !== "" && ( */}
                  <a
                    href={
                      meeting_link !== "" &&
                        meeting_link !== null &&
                        meeting_link !== undefined
                        ? meeting_link
                        : "javascript:void();"
                    }
                    target={
                      meeting_link !== "" &&
                        meeting_link !== null &&
                        meeting_link !== undefined
                        ? "_blank"
                        : ""
                    }
                    // onClick={() => {

                    //   setloaderType('1'); setTime(0);
                    //   setOndamandStatus(false)
                    // }}
                    className={`${meeting_link !== "" &&
                      meeting_link !== null &&
                      meeting_link !== undefined
                      ? ""
                      : "disabled-btn-join"
                      } width-415-px  font-size-22 pointer mb-3  indexstyles__StyledButton-sc-83qv1q-0 jdbotF sc-1qeub3n-4  d-flex align-items-center justify-content-center`}
                  >
                    Join Zoom Session Here
                  </a>
                  {/* )} */}
                  <div
                    onClick={() => {
                      navigate("/appointments?from=goondemand");
                      setloaderType("1");
                      setTime(0);
                      setOndamandStatus(false);
                    }}
                    className="width-415-px  pointer margin-unset indexstyles__StyledButton-sc-83qv1q-0 jdbotF-blacnk sc-1qeub3n-4  d-flex align-items-center justify-content-center"
                  >
                    {!shoHostStatus ? 'Find Zoom Link in My Appointments' : 'I Prefer to Find Zoom Link in Appointments Area'}
                  </div>
                </div>
              )}

            <div className="d-flex align-items-center justify-content-center mt-5 go-ondemand-practice-hover">
              <div className="col-md-4 hoverContent">
                <div className="hover-container d-flex justify-content-center ">
                  Having issues?{" "}
                  <p className="hover-target" tabindex="0">
                    <img src="/images/qu.png" className="ondemant-i-btn" />
                  </p>
                  <aside className="hover-popup-r">
                    <p className="mainpre">
                      Download the Zoom app to your device, then try connecting
                      again.
                    </p>
                    <p className="mainpre">
                      Contact us at{" "}
                      <a href="mailto:support@verysoul.com">
                        {" "}
                        support@verysoul.com
                      </a>{" "}
                      for further assistance.
                    </p>
                  </aside>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {loaderType == "5" && (
        <ChooseMedium
          rejectPaired={rejectPaired}
          acceptPaired={acceptPaired}
          hangon={hangon}
          seconds={seconds}
          matchName={matchName}
        />
      )}
      {/* {console.log(activeEvent, "timeLeft")} */}
      <ScheduledetailsModal
        info={info}
        setloaderType={setloaderType}
        Index={Index}
        checkMediumInOnDemand={checkMediumInOnDemand}
      />
      <ReminderPopUp activeEvent={activeEvent} />
      <AlredyJoined />
      <ConfirmOut />
      <TimeEndedPopup setloaderType={setloaderType} />
      <PartnerEndedPopup
        matchName={matchName}
        addMediumToQueueAgain={addMediumToQueueAgain}
      />
      {activeEvent !== undefined && <TimerForEvent activeEvent={activeEvent} />}
    </>
  );
}
