import moment from "moment";
import React, { useEffect, useState } from "react";

export default function PartnerEndedPopup({ matchName, addMediumToQueueAgain }) {


    return (
        <div>
            <div
                id="PartnerEndedPopup"
                class="modal fade bd-example-modal-lg mt-5 preview-modal-availability"
                tabindex="-1"
                role="dialog"
                aria-labelledby="myLargeModalLabel"
                aria-hidden="true"
                data-backdrop="static"
            >
                <div class="modal-dialog modal-lg modal-dialog-preview text-center">
                    <div class="modal-content p-5">
                        <h3>&nbsp;


                        </h3>
                        <br />
                        <h2>Sorry, {matchName} is no longer available.<br /> Let's find you another match.</h2>
                        <div id="msform">
                            <button class="next action-button m-3"
                                onClick={addMediumToQueueAgain}
                            >Okay</button>
                            {/* <input
                                type="button"
                                name="Close"
                                class="next action-button m-3"
                                value="Okay"
                                onClick={addMediumToQueueAgain}
                            /> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
